export const emailVerificationMessage = 'Email verification is required to receive email updates on order and invoices of order. An email has been sent with a link to activate your account.'

export const ORDER = {
    STATUS_POST_STAGE: 'status_post_stage',
    ON_PAYMENT: 'on_payment',
    CONFIRMED: 'on_confirmation',
    COMPLETED: 'order_completion',
    CANCELLED: 'order_cancellation',
    ON_INTIATE: 'on_initiate',
}

export const PAYMENTMODES = {
    COD: 'Cash On Delivery',
    RAZOR_PAY: 'Razorpay'
}


export default emailVerificationMessage