import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import Spin from 'antd/lib/spin'

export default props => {
  const mapState = useCallback(({ app }) => ({
    showLoader: app.get('showLoader')
  }), [])

  const { showLoader } = useSelector(mapState)
  if (!showLoader || !!props.show) {
    return null
  }

  return (
    <div className="__app-loader">
      <Spin size="large" />
    </div>
  )
}
