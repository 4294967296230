const prod = {
  url: {
    // IMAGE_BASE_URL: "https://abemart.in/",
    IMAGE_BASE_URL: "https://abemart.in/",
    //IMAGE_BASE_URL: "http://192.168.29.231::3000/",
    // IMG_URL: "http://192.168.1.5:3000/",
    // API_URL: "https://admin.abemart.in/api/",
    // IMAGE_BASE_URL: "https://jvkstore.corbel.biz/",
    // API_URL: "https://jvkstore.corbel.biz/api/",
    API_URL: "https://admin.abemart.in/api/",
    //  API_URL: "http://192.168.1.15:8003/api/",
  },

  URL_PREFIX: '',
  PLAY_STORE: 'https://play.google.com/store/apps/details?id=in.abemart.customer',
  APP_STORE: 'https://apps.apple.com/in/app/abemart/id1609600271',
  APP_NAME: 'Abemart Shopping',
  WHATSAPP:'https://api.whatsapp.com/send?phone=8590151204&text=Hello',
  CUSTOMER_SERVICE:'tel:+918590151204',
  CHAT: 'https://api.whatsapp.com/send?phone=8590151204&text=Hello',
  IS_PINCODE_ENABLED: false,
  DEFAULT_MART: 1,
  PRIMARY_MART: 2,
  
};


const dev = {
  url: {
    API_URL: "http://192.168.1.2:8000/api/",
  },
  URL_PREFIX: '',

};
const config = prod;//process.env.NODE_ENV === "development" ? dev : dev;





export default config