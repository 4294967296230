import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux'
import I from 'immutable'
import AppActionCreators from '../store/app/AppActionCreators';
import { AutoComplete, Input } from "antd";
import { asImmutable } from '../helpers/utils';
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router";

const renderTitle = (title) => (
    <span key={title} style={{ textTransform: 'capitalize' }}>
        {title}
    </span>
);
const renderItem = (title, category, id, index) => ({
    value: title,
    key: title + '_' + category + '_' + id + "_" + index,
    label: (
        <Link to={{
            pathname: "/search",
            search: "?mode=" + category + '&id=' + id//+'&page=1'
        }}>
            <div key={category + '_' + id + "_" + index} className="flex">
                {title}
                {/* {category === 'custom' ? <span className='__text_primary ml-1 flex flex-1 justify-end'>{' in all categories '}</span> : ''} */}
            </div>
        </Link>
    ),
});




const SearchBar = () => {


    const [items, setItems] = useState(I.List())
    const [categories, setCategories] = useState(I.List())
    const dispatch = useDispatch()

    const navigate = useNavigate()


    const fetchData = useCallback((search) => {
        // console.log('search:' + search)
        dispatch(AppActionCreators.requestFetchSearchList(search, (res) => {
            if (res) {
                var itemList = asImmutable(res)
                itemList = itemList.insert(0, asImmutable({ "mode": "custom", "result": search, "id": search }))
                var categories = [...new Set(itemList.map(data => data.get('mode')))]
                setItems(itemList)
                // console.log(itemList.toJS(), categories);
                setCategories(categories)
            }
        }))
    }, [dispatch])

    // const onSearch = useCallback((search) => {
    //     console.log('search 2:' + search)
    //     //     fetchData(search.trim())
    // }, [])


    const redirectSearch = (search) => {
        // console.log("redirect");
        navigate(`/search/?mode=custom&id=${search}`)
    }

    const [open, setOpen] = useState()
    return (
        <AutoComplete
            // open={open}
            className="__auto_search"
            dropdownClassName="certain-category-search-dropdown fixed"
            dropdownMatchSelectWidth={500}
            filterOption={false}
            backfill={true}
            // autoClearSearchValue={true}
            // defaultActiveFirstOption={false}
            // onSearch={onSearch}
            onChange={(e) => {
                // console.log(e);
                setOpen(true)
                fetchData(e);
            }}

            dropdownStyle={{ width: 200, maxWidth: 200 }}
            style={{ width: '100%' }}
            options={categories.map((category, i) => {
                return {
                    id: category + "-" + i,
                    label: category !== 'custom0' ? renderTitle(category) : null,
                    options: items.filter(x => x.get('mode') === category).map((item, index) => {
                        return renderItem(item.get('result'), item.get('mode'), item.get('id'), index)
                    }).toList()

                }
            })}
            onSelect={(e, a) => {
                // console.log("onSelect", e, a.name);
                setOpen(false)
            }}
        >


            {/* <Input prefix={<SearchOutlined style={{ color: "#1C9F47" }} />} placeholder="What are you searching for?" /> */}

            <Input.Search placeholder="What are you searching for?"
                allowClear
                enterButton
                style={{ color: "#1C9047" }}
                onSearch={(e, r) => {
                    if (e.trim().length > 0) {
                        // console.log(ref);
                        redirectSearch(e)
                        setOpen(false)
                    }
                }}
            />

        </AutoComplete>
    );
}

export default SearchBar