import React from "react";
import { Typography } from 'antd'

const { Title } = Typography

const ContentAccount = (props) => {

    return (<div className="flex flex-col w-100 h-100 overflow-auto">
        <div className="__title __content_shadow m-1">
            {typeof props.title == 'string' ?
                <Title type="success" level={3} style={{ fontWeight: 'normal' }}>{props.title}</Title> : props.title
            }
        </div>
        {/* <div style={{ height: 10 }}></div> */}
        <div className="__content_shadow m-1 p-2 lg:p-5 bg-white"
            style={{ maxHeight: '100%', flex: '1',  borderRadius: '8px' }}
        >
            {props.children}
        </div>
    </div>

    )
}

export default ContentAccount

