import { useCallback, useEffect, useState, useRef } from 'react';
import Row from 'antd/lib/row';
import Empty from 'antd/lib/empty';
import Pagination from 'antd/lib/pagination';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import I from 'immutable';
import List from "antd/lib/list";
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom'
import { asImmutable } from '../helpers/utils';
import AppActionCreators from '../store/app/AppActionCreators';
import ProductCard from '../widgets/product/ProductCard';
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from 'react-infinite-scroll-component';

const Offers = () => {

    const params = useParams();



    const [searchParams] = useSearchParams();


    const SearchId = params.id?.split('-')[0]

    const _page = searchParams.get('page') ?? 1


    const [error, setError] = useState()
    const [searchTitle, setSearchTitle] = useState()


    const [page, setPage] = useState(_page ?? 1)//

    const [items, setItems] = useState(I.List())
    const [reload, setReload] = useState()

    const [hasMore, setHasMore] = useState(true)
    const [loading, setloading] = useState(false)



    const dispatch = useDispatch()

    const fetchData = useCallback((page1) => {

        dispatch(AppActionCreators.requestFetchOffers(SearchId, (res, e) => {
            if (res) {

                let tt = page == 1 ? asImmutable(res.data) : items.push(...asImmutable(res.data))
                setItems(tt)
                // setHasMore(res.current_page <= res.last_page)
                setHasMore(false)
                setloading(false)

                setSearchTitle(res.offer_name)
                document.title = res.offer_name
            } else {
                setError(e.message ?? 'Error')
            }
        }))
    }, [items, SearchId, page, hasMore])



    useEffect(() => {
        setloading(true)
        setHasMore(true)
        setPage(1)
        setReload(Date.now())
    }, [searchParams, SearchId])

    useEffect(() => {

        if (reload)
            fetchData()// SearchId,
        // }
    }, [reload])



    useEffect(() => {

        if (params.id) {

        } else {
            setError('No Offer Found')
        }

    }, [params.id])



    const add2Cart = (batch_id, qty, cartItemId) => {
        dispatch(AppActionCreators.requestAdd2Cart(batch_id, qty, cartItemId))
    }
    const updateWishlist = (variant_id, a) => {
        dispatch(AppActionCreators.requestUpdateWishList(variant_id))
    }


    const loadMoreData = () => {
        if (loading) {
            return;
        }
        let p = page
        p++
        setPage(p)
        setReload(Date.now())
    };

    return (<>
        <Content className="site-layout" >

            {error ?
                <div className='m-1 md:m-5 p-0 md:p-5 text-center flex justify-center items-center' style={{ minHeight: 300 }}>
                    <Title
                        type="error"
                        level={2}
                        className="text-center w-100 uppercase text-3xl lg:text-5xl leading-normal" style={{ maxWidth: 500 }}> {error} </Title>
                </div>

                :


                loading ? null : <div
                    className="container __content_shadow bg-white py-5 my-6  flex flex-col flex-wrap"
                    //rounded
                    style={{ height: '100%' }}>

                    {searchTitle ?
                        <Title
                            type="success"
                            level={2}
                            className="text-center w-100"> {searchTitle} </Title>
                        : null}

                    <div className='container search-list-grid' id='scrollableDiv'>

                        <InfiniteScroll
                            dataLength={items.size}
                            next={loadMoreData}
                            hasMore={!loading && hasMore}
                            style={{ overflowX: 'hidden' }}
                        >
                            {
                                console.log('offers items', items.toJS())
                                
                            }

                            <List
                                className="__list_grid"
                                itemLayout="vertical"
                                grid={{
                                    gutter: 10,
                                    xs: 2,
                                    sm: 2,
                                    md: 2,
                                    lg: 3,
                                    xl: 4,
                                    xxl: 5,
                                }}
                                dataSource={items}
                                renderItem={(item, index) =>
                                    <ProductCard
                                        key={item.get('product_id')}
                                        centerMode={false}
                                        productId={item.get('product_id')}
                                        productName={item.get('product_name')}
                                        variants={item.get('variants')}
                                        productImage={item.getIn(['product_image'])}
                                        productRating = {item.get('rating') ?? 0}
                                        {...{ add2Cart, updateWishlist }} />
                                }
                            />
                        </InfiniteScroll>

                    </div>

                </div>
            }
        </Content >
    </>)
}
export default Offers