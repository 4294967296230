import { useCallback, useEffect, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import I from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { Link, } from 'react-router-dom'
import { asImmutable, formatTimeRange, getCookie, setCookies } from '../helpers/utils';
import AppActionCreators from '../store/app/AppActionCreators';
import { Alert, Badge, Button, Card, Col, Collapse, Empty, Form, Image, Input, Modal, Row, Select, Typography } from 'antd';
import { CaretRightOutlined, CheckCircleTwoTone, ClockCircleOutlined } from '@ant-design/icons';
import config from '../config/constants';
import moment from 'moment';




const DayFresh = () => {

    const mapState = useCallback(({ app }) => ({
        pincode: app.get('pincode'),
        // profile: app.getIn(['profile'], I.Map())
    }), [])

    const { pincode } = useSelector(mapState)
    document.title = "Groceries"


    const [error, setError] = useState()

    const [items, setItems] = useState(null)
    const [isPinModalVisible, setIsPinModalVisible] = useState(false);
    const [zipcodes, setZipcodes] = useState(I.List());
    const [zipType, setZipType] = useState();
    const [confirmLoading, setConfirmLoading] = useState();
    const [deliverySlots, setDeliverySlots] = useState(I.List());
    const [deliveryDayFreshSlots, setDeliveryDayFreshSlots] = useState(I.List());
    const [deliverySlot, setDeliverySlot] = useState();
    const [showDeliveryModal, setShowDeliveryModal] = useState();


    const dispatch = useDispatch()

    const fetchData = useCallback(() => {

        dispatch(AppActionCreators.requestFetchCategoryList(config.PRIMARY_MART, (res, e,) => {
            if (res) {
                let obj = {}
                let r = asImmutable(res)
                r.forEach(x => {
                    if (x.get('category_parent')) {
                        if (obj[x.get('category_parent')]) {
                            obj[x.get('category_parent')].push(x)
                        } else {
                            obj[x.get('category_parent')] = [x]
                        }
                    }
                })
                let t = r.filter(x => x.get('category_parent') == null)
                t = t.map(x => {
                    return x.set('data', asImmutable(obj[x.get('category_id')] ?? I.List()))
                })
                setItems(t)
            } else {
                setError(e.message ?? 'Error')
            }
        }))
    }, [])


    const getZipCodes = useCallback(() => {
        console.log(isPinModalVisible, !zipType);
        if (!zipType) {
            dispatch(AppActionCreators.requestFetchZipCodes(config.PRIMARY_MART, (res) => {
                if (res) {
                    let r = asImmutable(res)
                    setZipcodes(r.get('data'))
                    setZipType(r.get('type'))
                }
            }))
        }
    }, [isPinModalVisible])


    const setSlot = (item) => {
        setDeliverySlot(item)
        setCookies('delivery_time_id', item?.get('delivery_time_id'))
        setCookies('delivery_slot_count', item?.get('slotCount')) //additional field to check slot count from today/tmrw
    }


    // const getSlots = useCallback(() => {
    //     dispatch(AppActionCreators.requestFetchDeliverySlots(config.PRIMARY_MART, (res) => {
    //         if (res) {
    //             let data = asImmutable(res)
    //             setDeliverySlots(data)
    //             if (deliverySlot == null && !data.isEmpty()) {
    //                 let c = getCookie('delivery_time_id')
    //                 // array.reduce(function(total, currentValue, currentIndex, arr), initialValue)
    //                 let c1 = c ? data.reduce((a, b, i) => a.push(...b.getIn(['timeslots'])), I.List())
    //                     .filter(x => `${x.get('delivery_time_id')}` == `${c}`)?.first() : null

    //                 if (c1) {
    //                     setSlot(c1)
    //                 } else {
    //                     setSlot(data.getIn([0, 'timeslots', 0]))
    //                 }
    //             }
    //         }
    //     }))
    // }, [deliverySlot])


    const getDayFreshDeliverySlots = useCallback(() => { //New api integration
        dispatch(AppActionCreators.requestFetchDayFreshDeliverySlots((res) => {
            if (res) {
                let data = asImmutable(res)
                setDeliveryDayFreshSlots(data)
                if (deliveryDayFreshSlots.isEmpty() && !data.isEmpty()) {
                    // let c = getCookie('delivery_time_id')
                    // let c1 = c ? data?.find(item => item?.getIn(['data']).some(item2 => item2.get('delivery_time_id') == c))?.getIn(['data', 0]) : null;
                    let c1 = data?.find(item => !(item?.getIn(['data']).isEmpty()))?.getIn(['data', 0]);
                    const slotIndex = data?.findIndex((it, index)=> it?.get('data')?.some(el=>el?.get('delivery_sort_id') == c1?.getIn(['delivery_sort_id'])));

                    console.log('c1', c1.toJS(), slotIndex == -1 ? 0: slotIndex);
                    
                    if (c1) {
                        setSlot(c1.set('slotCount', slotIndex == -1 ? 0: slotIndex))
                    } else {
                        const slotsData = !data.getIn([0, 'data']).isEmpty() ? data?.getIn([0, 'data', 0]) : data.getIn([1, 'data', 0]);
                        setSlot(slotsData.set('slotCount', slotIndex == -1 ? 0: slotIndex))
                    }
                }
            }
        }))
    }, [deliveryDayFreshSlots])




    // const fetchZipCodesData = useCallback(() => {
    //     if (!getCookie('pincode') && config.IS_PINCODE_ENABLED) {
    //         // console.log('pin');
    //         setIsPinModalVisible(true);
    //     } else {
    //         //   setPinCode(getCookie('pincode'))
    //     }

    // }, [dispatch])

    useEffect(() => {
        // console.log(getCookie('pincode'));
        // getSlots()
        getDayFreshDeliverySlots();
        if (getCookie('pincode')) {
            // fetchZipCodesData()
            fetchData()
        } else {
            setIsPinModalVisible(true)
            getZipCodes()
            // dispatch(AppActionCreators.setShowPinCode(true))
        }
    }, [pincode])




    return (<>
        <Content className="site-layout" >


            {error ?
                <div className='m-1 md:m-5 p-0 md:p-5 text-center flex justify-center items-center' style={{ minHeight: 300 }}>
                    <Title
                        type="error"
                        level={2}
                        className="text-center w-100 uppercase text-3xl lg:text-5xl leading-normal" style={{ maxWidth: 500 }}> {error} </Title>
                </div>
                :
                <div
                    className="container __content_shadow bg-white py-5 my-6  flex flex-col flex-wrap"
                    style={{ height: '100%' }}>

                    <Title
                        type="secondary"
                        level={2}
                        className="text-center w-100 mb-2">Groceries</Title>



                    <div className='container px-3 flex-col px-2 lg:px-5'>


                        {deliverySlot ? <div className='p-1 mb-3 text-center md:text-right'>

                            <Typography.Text className='text-end' onClick={() => setShowDeliveryModal(true)}>
                                <span className='font-semibold mt-3'>Delivery Slot :</span>
                                <Button className='pl-2 __text_primary font-bold border border-green-500 rounded-md ms-2 hover:bg-green-50'>
                                    <div className='flex items-center gap-1'>
                                        <ClockCircleOutlined className='mt-0' />
                                        {/* {
                                            moment(deliverySlot.get('delivery_time_start'), "HH:mm:ss").format("hh:mm a") + ' - ' +
                                            moment(deliverySlot.get('delivery_time_end'), "HH:mm:ss").format("hh:mm a")
                                        } */}
                                        {moment(deliverySlot?.get('delivery_time_name'), 'HH:mm:ss', true).isValid()
                                            ? moment(deliverySlot?.get('delivery_time_name')).format('HH:mm a')
                                            : formatTimeRange(deliverySlot?.get('delivery_time_name'))
                                        }

                                    </div>
                                </Button>
                            </Typography.Text>


                        </div> : null}

                        {items?.isEmpty() ? <>

                            <div>

                                <Alert
                                    className='rounded-lg my-3'
                                    message="Delivery Not Available"
                                    description="We're sorry! Unfortunately, we are not currently available in this location. Please try entering a different pin-code, and we’ll be happy to serve you!"
                                    type="error"
                                    showIcon
                                />
                            </div>

                        </> :


                            <Collapse
                                bordered={false}
                                expandIconPosition="right"
                                defaultActiveKey={['1']}
                                className='__category_collapse __secondary0 overflow-hidden mb-4'
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                style={{
                                    background: 'white',
                                    border: '1px solid #e9e9e9',
                                    borderRadius: 10,
                                    padding: 0,
                                    margin: 0
                                }}

                            // items={getItems(panelStyle)}
                            >

                                {
                                    items?.map(x =>
                                        <Collapse.Panel
                                            key={x.get('category_id')}
                                            header={<div className='items-center flex' >
                                                <Image
                                                    preview={false}
                                                    src={x.get('category_image')}
                                                    width={64}
                                                    height={64}

                                                />
                                                <Link to={{
                                                    pathname: "/search",
                                                    search: "?type=dayfresh&title=" + encodeURIComponent(x.get('category_name')) + "&mode=category&id=" + x.get('category_id')

                                                }} >
                                                    <span className='ml-3'> {x.get('category_name')}</span>
                                                </Link>
                                            </div>}
                                        >

                                            <Row className=' flex-wrap mx-0 py-3 px-2 lg:px-5 ' gutter={[12, 12]} style={{ backgroundColor: '#f0f8eb' }}>

                                                {x.get('data', I.List()).isEmpty() ? <div className='pl-5 text-center'>No Items Found</div> : x.get('data')?.map(x1 => {
                                                    return <Col
                                                        key={x1.get('category_id')}>
                                                        <Link to={{
                                                            pathname: "/search",
                                                            search: "?type=dayfresh&title=" + encodeURIComponent(x1.get('category_name')) + "&mode=category&id=" + x1.get('category_id')

                                                        }}
                                                            className='flex'>
                                                            <Card
                                                                bordered
                                                                style={{ borderRadius: 20 }}
                                                                bodyStyle={{ padding: '5px 15px' }}
                                                            >
                                                                {x1.get('category_name')}
                                                            </Card>
                                                        </Link>
                                                    </Col>
                                                })}
                                            </Row>
                                        </Collapse.Panel>)
                                }
                            </Collapse>
                        }
                    </div>
                </div>
            }

            <Modal
                title={null} footer={null}
                visible={isPinModalVisible && zipType}
                destroyOnClose={true}
                onCancel={() => {
                    if (getCookie('pincode')) {
                        setIsPinModalVisible(false)
                    }
                }}>
                <Form
                    onFinish={(values) => {
                        // setIsPinModalVisible(false);
                        if (values.pincode) {
                            setCookies("pincode", values.pincode);
                            setIsPinModalVisible(false)
                            fetchData()
                            dispatch(AppActionCreators.setPinCode(values.pincode))
                        }
                        // setPinCode(values.pincode)

                    }}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    onCancel={null}
                >

                    {
                        zipType ? (zipType == "10" ?
                            <Form.Item
                                name="pincode"
                                label="Please enter your pincode"
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                rules={[{ required: true, message: 'Please input your pincode!' }, {
                                    message: "Pincode must be digits.",
                                    pattern: new RegExp(/^[0-9]+$/)
                                }, { min: 6, max: 6, message: 'Pincode must be 6 digits.' }]}
                            >
                                <Select placeholder="Please select your Pincode">
                                    {zipcodes.map((zipcodes, index) => {
                                        return <Select.Option key={index} value={zipcodes.get('pincode')}>{zipcodes.get('pincode')}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item> :
                            <Form.Item
                                name="pincode"
                                label="Please enter your pincode"
                                rules={[{ required: true, message: 'Please input your pincode!' }, {
                                    message: "Pincode must be digits.",
                                    pattern: new RegExp(/^[0-9]+$/)
                                }, { min: 6, max: 6, message: 'Pincode must be 6 digits.' }]}
                            >
                                <Input
                                    tabIndex="1"
                                    type="text"
                                    className="pincodeInput"
                                    maxLength='6'
                                />

                            </Form.Item>
                        ) : null}

                    <Form.Item
                        wrapperCol={{ offset: 12, span: 12 }}
                        className="mb-2">
                        <Button tabIndex="2"
                            loading={confirmLoading}
                            type='primary' htmlType="submit" style={{ minWidth: 120 }}>
                            Set Pincode
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>





            <Modal
                title={
                    <Row justify="space-between" className='align-middle'>
                        <Col>
                            <Title type="success" className='text-white mb-0 align-middle font-normal' level={5} >Change Time Slot</Title>
                        </Col>
                    </Row>
                }
                footer={null}
                visible={showDeliveryModal}
                destroyOnClose={true}
                onCancel={() => {
                    setShowDeliveryModal()
                    // if (getCookie('pincode')) {
                    //     setIsPinModalVisible(false)
                    // }
                }}>


                {deliveryDayFreshSlots?.map((x, index) => {
                    return <div key={index} class="__day_container border border-green-700 my-5 rounded-md">
                        <div className='__day_label -translate-y-4 bg-white mx-3 px-2 w-fit'>{x?.get('label') ?? `Slot ${index + 1}`}</div>
                        <div className='px-3 pb-3'>
                            {
                                !x?.get('data').isEmpty() ? x?.get('data')?.map((x2, i2) => {
                                    return <Badge className='w-full' count={x2.get('delivery_time_id') === deliverySlot?.get('delivery_time_id') && deliverySlot?.get('slotCount') == index ? <CheckCircleTwoTone twoToneColor="#24ce62" /> : 0} offset={[-5, 5]}>
                                        <Button size='large' key={x2?.get('delivery_time_id') ?? index} className={`w-full flex items-center justify-center my-2 rounded-lg text-green-600 ${x2.get('delivery_time_id') === deliverySlot?.get('delivery_time_id') && deliverySlot?.get('slotCount') == index ? 'selected' : ''}`}
                                            onClick={() => {
                                                setSlot(x2.set('slotCount', index)) //field to check same slot from today or tmrw 
                                                setShowDeliveryModal()
                                                fetchData()
                                            }}
                                        >
                                            <ClockCircleOutlined className='my-0 py-0 text-sm' />
                                            {
                                                formatTimeRange(x2?.get('delivery_time_name'))
                                            }
                                        </Button>
                                    </Badge>
                                }) : <div className='flex justify-center items-center'><Empty imageStyle={{ height: 60 }} description="No Slots Available" /></div>
                            }

                        </div>
                    </div>
                })}

                {/* {
                    addressList.map((item, index) => {
                        return <AddressModalItem key={index} {...{ item }} onSelected={onSelected} />
                    })
                } */}
            </Modal>
        </Content >
    </>)
}
export default DayFresh