import { Button, Card, Col, Collapse, Divider, List, Modal, Row, Typography, Radio, Input, Form, Image } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentAccount from "../../components/ContentAccount";
import moment from 'moment'
import { TOAST } from "../../helpers/NotificationHelper";
import AppActionCreators from "../../store/app/AppActionCreators";
import I from 'immutable'
import { asImmutable } from "../../helpers/utils";
import { DeleteFilled, MinusOutlined, PlusOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useNavigate } from "react-router";
import config from "../../config/constants";
import { PAYMENTMODES } from "../../config/strings";
import { Link } from "react-router-dom";
import { EnvironmentTwoTone, HeartFilled } from '@ant-design/icons'
import { ModalAddressChange } from "../../components/navbar";


const { Panel } = Collapse
const { Text, Title } = Typography


function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const Cart = (props) => {

    document.title = "Cart"



    const [isTimeSlotModalVisible, setIsTimeSlotModalVisible] = useState(false)
    const [checkout, setCurrentCheckout] = useState(I.Map())
    const [availableTimeSlots, setAvailableTimeSlots] = useState(I.List())
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState()
    const [voucher, setVoucher] = useState()

    const { setLoginPanelVisible } = props

    const dispatch = useDispatch()


    const [voucherSettings, setVoucherSettings] = useState({ visible: false, loading: false })

    const [isAddressVisible, setIsAddressVisible] = useState()
    const [form] = Form.useForm()


    const fetchCart = useCallback(() => {
        dispatch(AppActionCreators.requestFetchCartV2((res) => {
            if (res) {
                // console.log(res);
                // setCart(asImmutable(res))
            }
        }))
    })



    const mapState = useCallback(({ app }) => ({
        // cart: app.getIn(['cart'], I.Map()),
        cart_details: app.getIn(['cart_details'], I.Map()),
        profile: app.getIn(['profile'], I.Map()),
    }), [])

    // const { cart } = useSelector(mapState)
    const { cart_details, profile } = useSelector(mapState)

    async function displayRazorpay(order) {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        const options = {
            key: process.env.RAZORPAY_API_KEY,
            amount: order.getIn(['net_amount']),
            order_id: order.getIn(['razorpay_order_id']),
            name: config.APP_NAME,
            description: `${order.getIn(['razorpay_order_id'])} item${order.getIn(['razorpay_order_id']) > 1 ? 's' : ''}`,
            timeout: 180,
            handler: function (response) {
                console.log("razor response", response);
                paymentModeHandler(response, order, value)
            },
            prefill: {
                name: order.getIn(['billing_address', 'address_name']),
                email: profile.getIn(['email']),
                contact: order.getIn(['billing_address', 'address_phone'])
            }
        }
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }

    const add2Cart = (batch_id, qty, cartItemId) => {
        dispatch(AppActionCreators.requestAdd2Cart(batch_id, qty, cartItemId))
    }

    useEffect(() => {
        fetchCart()
    }, [])

    const [value, setValue] = useState('RAZOR_PAY');

    // const onChange = (e) => {
    //     console.log('radio checked', e.target.value);
    //     setValue(e.target.value);
    // };


    const navigate = useNavigate()
    const paymentModeHandler = useCallback((response, order, paymentType) => {

        let paymentId = value === 'RAZOR_PAY' ? response.razorpay_payment_id : 0
        let __msg = value === 'RAZOR_PAY' ? 'Payment Success' : 'Pay on Delivery'

        purchaseEvent(order.toJS(), paymentId)

        dispatch(AppActionCreators.requestSetPaymentStatus(order.getIn(['order_id']), 'success', paymentId, paymentType, (res) => {
            // orderId, paymentStatus, paymentId, paymentType, cb
            if (res) {
                TOAST.success(__msg)
                setCurrentCheckout(I.Map())
                fetchCart()
                let orderId = res.order_id
                let order_no = res.order_no
                navigate("/account/ordersPlaced?order_id=" + orderId + "&order_no=" + order_no)


            }
        }))
    })


    const purchaseEvent = (order, paymentId) => {
        //  if (resData?.order_id) {

        if (order?.net_amount > 0) {
            window.dataLayer?.push({ 'ecommerce.purchase': null });
            let items = order.delivery_methods?.map(x => (x.items)).flat(1)

            let ii = items.map((x, i) => ({
                item_id: x?.data?.variant_sku,
                item_name: x?.order_item_product_title,
                discount: x?.order_item_discount,
                index: i,
                item_variant: x?.order_item_variant_name,
                price: x?.order_item_rate,
                quantity: x.order_item_quantity
            }))

            window.dataLayer?.push({
                event: "purchase",
                ecommerce: {
                    purchase: {
                        transaction_id: paymentId,
                        currency: "INR",
                        value: order?.net_amount,
                        items: ii
                    }
                }
            })
        }
        //   }
    }


    const onCartCheckout = useCallback(async () => {
        var newCheckout = I.Map()

        dispatch(AppActionCreators.requestSetCartCheckout(voucher, (postRes, error_code) => {
            if (postRes) {
                newCheckout = asImmutable(postRes)
                setCurrentCheckout(asImmutable(newCheckout))

            } else if (error_code == 436) {
                navigate("/account/address?redirect=cart")
            } else if (error_code == 437) {
                navigate("/account/address/add?redirect=cart")
            }else if(error_code == 435){
                navigate("/account/profile")
            }
        }))
    }, [voucher])

    const applyVoucher = useCallback((voucher) => {


        setVoucherSettings({ ...voucherSettings, loading: true })

        // setConfirmLoading(true)

        dispatch(AppActionCreators.requestCheckVoucher(voucher, (res) => {
            // console.log(res);
            setVoucherSettings({ ...voucherSettings, loading: false, error_code: res.error_code, message: res.message, amount: res.amount })
        }))
    }, [voucherSettings])


    useEffect(() => {
        if (voucher && checkout.isEmpty()) {
            applyVoucher(voucher)
        }

    }, [cart_details, voucher])




    function handleCashOnDelivery(order) {
        paymentModeHandler('COD', order, value)
    }
    const onPlaceOrder = useCallback((order) => {
        {
            value == 'COD' ? Modal.confirm({
                title: 'Confirm',
                icon: <ExclamationCircleOutlined />,
                content: 'Are you sure that you want to proceed with Cash on Delivery?',
                okText: 'Confirm',
                cancelText: 'Cancel',
                onOk: () => {
                    handleCashOnDelivery(order)
                },
                onCancel: () => { }
            }) : displayRazorpay(order)
        }
        // { value == 'COD' ? handleCashOnDelivery(order) : displayRazorpay(order)}
    }, [value])

    const onCancelOrder = useCallback((orderId) => {
        dispatch(AppActionCreators.requestCancelOrder(orderId, (res) => {
            if (res) {
                TOAST.success('Order cancelled')
                setCurrentCheckout(I.Map())
            }
        }))
    })

    const fetchTimeSlots = (e, orderDeliveryMethod) => {
        e.stopPropagation()
        setIsTimeSlotModalVisible(true)
        dispatch(AppActionCreators.requestFetchAvailableTimeSlot(orderDeliveryMethod.getIn(['order_delivery_method_id'], null), (res) => {
            if (res) {
                console.log(res);
                setSelectedDeliveryMethod(orderDeliveryMethod)
                setAvailableTimeSlots(asImmutable(res))
            }
        }))
    }

    const setTimeSlot = (deliveryTimeId, orderDeliveryMethodId, deliveryTimeCount,) => {
        dispatch(AppActionCreators.requestSetTimeSlot(deliveryTimeId, orderDeliveryMethodId, deliveryTimeCount, (res) => {
            if (res) {
                console.log(res)
                setCurrentCheckout(asImmutable(res))
                setIsTimeSlotModalVisible(false)
            }
        }))
    }




    const deliveryMethodPanel = (deliveryMethod) => {
        return <>
            <Row>
                <Col>
                    <img style={{ height: 100, width: 100, objectFit: 'contain' }} src={deliveryMethod.getIn(['items', 0, 'data', 'product', 'product_image'])} alt="" />
                </Col>
                <Col className="px-5">
                    <Row>
                        {deliveryMethod.getIn(['net_amount']) ? <Title className="mb-0" level={3}>{'₹ ' + deliveryMethod.getIn(['net_amount'])}</Title> : ''}<br />
                    </Row>
                    <Row>
                        <Title level={4} className="text-gray-500 font-normal mb-0">
                            {deliveryMethod.getIn(['item_count'])}{deliveryMethod.getIn(['item_count']) > 1 ? ' items' : ' item'} will be delivered on
                        </Title>
                    </Row>
                    <Row>
                        <Title level={5} className="text-green-600 font-normal mb-0">{deliveryMethod.getIn(['expected_date_of_delivery'])}{deliveryMethod.getIn(['delivery_time_details', 'delivery_time_name'], null) ? ' | ' + deliveryMethod.getIn(['delivery_time_details', 'delivery_time_name'], null) : ''}{deliveryMethod.getIn(['delivery_method_choose_time_slot']) === 10 ?
                            <Button onClick={(e) => fetchTimeSlots(e, deliveryMethod)} type="link" className="font-medium text-amber-600">
                                Change
                            </Button> : ''}
                        </Title>
                    </Row>

                </Col>
            </Row>

        </>
    }

    return (
        <>

            <div className="container justify-content-center">
                <Row gutter={[24, 24]} className="w-100 mx-0 py-5 px-1"
                    style={{ minHeight: '60vh' }}>
                    <Col span={24} lg={16} className="__content_shadow bg-gray-50 rounded-lg py-4 mr-5">
                        <Title
                            type="success"
                            level={2}
                            className="px-5"
                        >{checkout.isEmpty() ? 'Cart Items' : 'Order Items'}</Title>



                        {
                            checkout.isEmpty() && cart_details?.getIn(['cart_items'], I.List()).isEmpty() ?
                                <div className="text-center items-center m-auto h-100 flex flex-col">
                                    <div className="flex flex-col m-auto">
                                        <img className="mx-auto" src="/app/grocery.png" style={{ maxWidth: 240 }} />
                                        <Title level={3} className="my-0 flex mx-auto text-gray-600" >
                                            No Items in Cart
                                        </Title>
                                        <div className="text-gray-500">
                                            <HeartFilled className="mx-1" style={{ color: "red", fontSize: '1rem' }} /> add items to checkout
                                            <HeartFilled className="mx-1" style={{ color: "red", fontSize: '1rem' }} />
                                        </div>
                                    </div>
                                </div>
                                : null
                        }



                        {/* {
                            cart_details.getIn(['cart_items'], I.List()).map((x, index) =>
                                <Collapse
                                    bordered={false}
                                    expandIconPosition="right"
                                    defaultActiveKey={['0']}
                                    ghost
                                    className='__category_collapse overflow-hidden mb-4'
                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                    style={{
                                        background: 'white',
                                        border: '1px solid #e9e9e9',
                                        borderRadius: 10,
                                        padding: 0,
                                        margin: 0
                                    }}

                                // items={getItems(panelStyle)}
                                >

                                    <Collapse.Panel
                                        key={x.get('category_id')}
                                        header={<div className='items-center flex' >
                                            <Title level={4}>{x.get('name')} </Title>
                                        </div>}
                                        className="p-1"
                                    >
                                        {x.getIn(['cart_items'], I.List()).map((item, index) => {

                                            return <CartItem
                                                key={index}
                                                {...{ item, add2Cart }}
                                            />
                                        })}



                                    </Collapse.Panel>
                                </Collapse>
                            )
                        } */}


                        {
                            checkout.isEmpty() ?

                                <Row gutter={[12, 12]} className="lg:px-3">
                                    {cart_details.getIn(['cart_items'], I.List()).map((item, index) => {
                                        return <CartItemGroup
                                            key={index}
                                            {...{ item, add2Cart }}
                                        />
                                    })}
                                </Row>

                                : checkout.getIn(['delivery_methods'], null).map((deliveryMethod, index) => {
                                    return <div key={index} className="mb-2">
                                        <Collapse
                                            key={index}
                                            className="shadow-md overflow-hidden rounded-lg bg-white"
                                            expandIcon={({ isActive }) => <DownOutlined className="p-2 bg-green-600 shadow-md hover:bg-green-700 transition ease-in-out duration-400 text-md text-white rounded font-black" rotate={isActive ? 180 : 0} />}
                                            expandIconPosition='right'
                                            bordered={true}
                                            defaultActiveKey={['0']} >
                                            <Panel
                                                className="border-none "
                                                header={deliveryMethodPanel(deliveryMethod)}>
                                                <List
                                                    itemLayout="vertical"
                                                    className="product-listing"
                                                    dataSource={deliveryMethod.getIn(['items'])}
                                                    renderItem={(orderItem, index) => {

                                                        return <List.Item
                                                            key={index}
                                                            className='rounded pl-4 lg:ml-3'
                                                        >
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <img
                                                                        key={index} className='rounded-md shadow-md'
                                                                        style={{ height: 60, width: 60, objectFit: 'contain' }}
                                                                        src={orderItem.getIn(['data', 'images', 0, 'variant_images_name']) ?? orderItem.getIn(['data', 'product', 'product_image'])} />

                                                                }

                                                                title={<span>{orderItem.getIn(['data', 'product', 'product_name'])}<br />{orderItem.getIn(['data', 'variant_name'])}</span>}
                                                                // title={orderItem.getIn(['data', 'variant_name'])}
                                                                description={<span>{
                                                                    orderItem.getIn(['order_item_net_amount'], null) ?
                                                                        <span className='text-lg text-green-600'>₹
                                                                            {orderItem.getIn(['order_item_net_amount'], null)
                                                                            }</span>
                                                                        : <span className='text-red-700'>Out of Stock</span>}

                                                                </span>}
                                                            />
                                                        </List.Item>
                                                    }
                                                    }

                                                />
                                            </Panel>
                                        </Collapse>
                                    </div>
                                })
                        }
                        <Modal destroyOnClose title="Change Time Slot "
                            visible={isTimeSlotModalVisible}
                            onCancel={() => setIsTimeSlotModalVisible(false)}>
                            <List
                                itemLayout="vertical"
                                dataSource={availableTimeSlots}
                                renderItem={(timeSlot, index) => (
                                    <List.Item key={index}>
                                        <Card
                                            className={(timeSlot.getIn(['delivery_time_id'], 0) == selectedDeliveryMethod.getIn(['delivery_time_details', 'delivery_time_id'], 0))
                                                && ((timeSlot.getIn(['delivery_time_count'], 0) ??0 )== (selectedDeliveryMethod.getIn(['delivery_time_count'], 0) ?? 0)) ? 'bg-green-100' : ''}
                                            onClick={() => {
                                                // deliveryMethod.getIn(['order_delivery_method_id'], null)
                                                setTimeSlot(timeSlot.getIn(['delivery_time_id'], null), selectedDeliveryMethod?.getIn(['order_delivery_method_id'], null), timeSlot.getIn(['delivery_time_count'], null))
                                            }} hoverable>
                                            <Title level={4} className='text-center'>{timeSlot.getIn(['delivery_time_name'], null)}</Title>
                                            <Row justify="center">
                                                <Col span={3}>
                                                    <Title level={5} className='font-normal'>From: </Title>
                                                </Col>
                                                <Col span={4}>
                                                    <Title level={5}>{moment(timeSlot.getIn(['delivery_time_start'], null), "HH:mm:ss").format("hh:mm A")}</Title>
                                                </Col>
                                            </Row>
                                            <Row justify="center">
                                                <Col span={3}>
                                                    <Title level={5} className='font-normal'>To: </Title>
                                                </Col>
                                                <Col span={4}>
                                                    <Title level={5}>{moment(timeSlot.getIn(['delivery_time_end'], null), "HH:mm:ss").format("hh:mm A")}</Title>
                                                </Col>
                                            </Row>

                                        </Card>
                                    </List.Item>
                                )} />
                        </Modal >
                    </Col>
                    <Col span={24} lg={6} className="__content_shadow bg-gray-50 p-4 rounded-lg">

                        {
                            checkout.isEmpty() ?
                                <>
                                    <Title level={4} className=' '>Cart Summary</Title>
                                    <Divider />
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>No. of items:</Title>
                                        </Col>
                                        <Col>

                                            <Title level={5} className='bold'>{cart_details.getIn(['cart_item_count'], 0)}</Title>
                                        </Col>

                                    </Row>
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>Subtotal:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>₹ {cart_details.getIn(['gross_amt'], 0)}</Title>
                                        </Col>

                                    </Row>
                                    <Divider />
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>Delivery fee:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>{cart_details.getIn(['delivery_fee'], 0) > 0 ? <>₹ {cart_details.getIn(['delivery_fee'], 0)}</> : 'Free Delivery'}</Title>
                                        </Col>

                                    </Row>
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>Discount:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>₹ {cart_details.getIn(['cart_discount'], '0.00')}</Title>
                                        </Col>

                                    </Row>
                                    <Divider className="mb-1" />





                                    {voucherSettings.amount > 0 ? null :

                                        <Form
                                            className=""
                                            name="__form"
                                            form={form}
                                            // autoComplete="new-state"
                                            onFinish={(values) => {
                                                if (!voucherSettings.visible) {
                                                    setVoucherSettings({ ...voucherSettings, visible: true })
                                                    return
                                                }
                                                setVoucher(values.voucher)
                                            }}>
                                            <Row justify="space-between mt-3">

                                                {voucherSettings.visible ?
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="voucher"
                                                            label=""
                                                            className="mb-0"
                                                            value={voucher}
                                                            onChange={(e) => {
                                                                let text = e.target.value.toUpperCase()
                                                                form.setFieldsValue({
                                                                    'voucher': text
                                                                })
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Enter Voucher Code'
                                                                },
                                                                { min: 12, max: 12, message: '12 Characters required' },
                                                                {
                                                                    message: "Invalid Characters",
                                                                    pattern: new RegExp(/^[a-zA-Z0-9]+$/)
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                // ref={refVoucher}
                                                                // value={voucher}
                                                                allowClear
                                                                autoComplete="nope"

                                                                maxLength={12}
                                                                // pattern={/^[a-zA-Z0-9]*$/}
                                                                // pattern={/^[a-zA-Z0-9-]+$/i}
                                                                // pattern: new RegExp(),
                                                                type="text"
                                                                onChange={e => {
                                                                    let text = e.target.value.toUpperCase()

                                                                    // // text =text.replaceAll('-','')
                                                                    // setVoucher(text)
                                                                    // let t = text.split("(?<=\\G.{4})")   
                                                                    if (text.trim() == '') {
                                                                        setVoucherSettings({ ...voucherSettings, error_code: '', message: '' })
                                                                        setVoucher()
                                                                    }
                                                                    // let t = text.match(/.{1,4}/g)   

                                                                    // if(text.le)

                                                                    //Get the value
                                                                    // text = text.replace(/([A-Za-z0-9_.]+{3})(\d{3})(\d{4})/, "$1-$2-$3") //.replace(/\D/g, '')                        //Remove illegal characters 
                                                                    // text = text.replace(/[A-Z][A-Z0-9]+/) //.replace(/\D/g, '')                        //Remove illegal characters 
                                                                    // text = text.replace(/.{3}(?!$)/g, '$&-') //.replace(/\D/g, '')                        //Remove illegal characters 
                                                                    // if (text.length > 3) text = text.replace(/.{3}/, '$&-')  //Add hyphen at pos.4
                                                                    // if (text.length > 7) text = text.replace(/.{7}/, '$&-')  //Add hyphen at pos.8
                                                                    // console.log(text, t);

                                                                    // setVoucher(t?.join('-'))
                                                                    // $(this).val(text);
                                                                    // refVoucher.current.value = text
                                                                }} />
                                                        </Form.Item>
                                                    </Col> : null}
                                                <Col span={24} className="mt-2" align="end" >
                                                    <Form.Item className="mb-0 ml-5"
                                                    >
                                                        <Button tabIndex="2"
                                                            loading={voucherSettings.loading}
                                                            className="__bt_voucher"
                                                            // type='text'
                                                            htmlType="submit"
                                                        // style={{ minWidth: 120 }}
                                                        >
                                                            Apply Voucher
                                                        </Button>
                                                    </Form.Item>

                                                </Col>
                                            </Row>
                                        </Form>
                                    }
                                    {voucherSettings.message ?
                                        <div className={`${voucherSettings.error_code ? 'text-red-500' : 'text-green-500 font-bold'} text-center my-2`}>

                                            {voucherSettings.message}

                                        </div> : null
                                    }

                                    {voucherSettings.visible && voucherSettings.error_code == null && voucherSettings.amount > 0 ?
                                        <div className="text-right">
                                            <Link to="#" className="text-red-400 "
                                                onClick={() => {
                                                    form.setFieldsValue({
                                                        'voucher': ''
                                                    })
                                                    setVoucher()
                                                    setVoucherSettings({ ...voucherSettings, amount: null, error_code: null, message: null, visible: false })
                                                }}
                                            >
                                                remove
                                            </Link>
                                        </div>

                                        : null}
                                    <Divider className="mt-3" />

                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>Total:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>₹ {(cart_details.getIn(['net_total_amt'], 0) - (voucherSettings?.amount ?? 0)) < 0 ? 0 : (cart_details.getIn(['net_total_amt'], 0) - (voucherSettings?.amount ?? 0))}</Title>
                                        </Col>

                                    </Row>
                                    {profile && !profile.getIn(['isGuest'], true) ? <>
                                        <Divider className="mt-3" />
                                        <Row justify="space-between">
                                            <Col span={24}>
                                                <Title level={5} className='font-light'>
                                                    Address:</Title>

                                            </Col>
                                            <span className="__hover __anchor text-sm line-clamp-1" onClick={() => setIsAddressVisible(true)}>
                                                <EnvironmentTwoTone className='px-1' style={{ fontSize: '20px' }} twoToneColor="#1C9F47" />
                                                {profile.getIn(['address', 'address_building']) ?
                                                    <>{profile.getIn(['address', 'address_building'])},
                                                        <br /></> : null}
                                                {/* {profile.getIn(['address', 'address_zip'], '') == '111111' ? null : profile.getIn(['address', 'address_zip'], '')} */}
                                            </span>
                                        </Row></>
                                        : null}

                                    {isAddressVisible ? <ModalAddressChange {...{ isAddressVisible, setIsAddressVisible }} /> : null}

                                    <div className="" style={{ height: '2rem' }}></div>
                                    {
                                        !(cart_details.getIn(['error_code'])) ?
                                            <Button onClick={() => profile.getIn(['isGuest']) ? setLoginPanelVisible(true) : onCartCheckout()}
                                                style={{ width: '100%' }} type="primary" htmlType="submit">
                                                Checkout
                                            </Button> :
                                            null
                                    }

                                </> :

                                <>
                                    <Title level={4} >Order Summary</Title>

                                    {checkout?.get('order_create_note') ? <>
                                        <Divider className="mb-3" />
                                        <Title level={5} className='text-center text-red-500 m-0'>{checkout?.get('order_create_note')}</Title>
                                        <Divider className="mt-3" />
                                    </> : <Divider />}
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>No. of items:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>{checkout.getIn(['item_count'], 0)}</Title>
                                        </Col>

                                    </Row>
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>Subtotal:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>₹ {checkout.getIn(['gross_amt'], 0)}</Title>
                                        </Col>

                                    </Row>
                                    <Divider />
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>Delivery fee:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>{cart_details.getIn(['delivery_fee'], 0) > 0 ? <>₹ {cart_details.getIn(['delivery_fee'], 0)}</> : 'Free Delivery'}</Title>
                                        </Col>

                                    </Row>
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>Discount:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>₹ {checkout.getIn(['cart_discount'], '0.00')}</Title>
                                        </Col>

                                    </Row>
                                    <Divider />
                                    {checkout.getIn(['voucher_amount'], 0) > 0 ?
                                        <>
                                            <Row justify="space-between">
                                                <Col>
                                                    <Title level={5} className='font-light'>Voucher Applied:</Title>
                                                </Col>
                                                <Col>
                                                    <Title level={5} type="success" className='bold '> -₹{parseFloat(checkout.getIn(['voucher_amount'], '0'))}</Title>
                                                </Col>

                                            </Row>
                                            <Divider className="mt-2" />
                                        </>
                                        : null}
                                    <Row justify="space-between">
                                        <Col>
                                            <Title level={5} className='font-light'>Total:</Title>
                                        </Col>
                                        <Col>
                                            <Title level={5} className='bold'>₹{checkout.getIn(['net_amount'], 0)}</Title>
                                        </Col>

                                    </Row>
                                    <Divider />
                                    {/* <div>
                                        <Row justify="center">
                                            <Title level={5} className='font-bold'>Select Payment Mode</Title>
                                        </Row>
                                        <Row justify="space-between">
                                            <Radio.Group className="w-100" onChange={onChange} value={value}>
                                                <Row className={value == 'COD' ? "bg-green-100" : ""} justify="space-between">
                                                    <Col className="flex items-center px-3">
                                                        <Radio value={'COD'} style={{  justifyContent: 'center' }}>{PAYMENTMODES.COD}</Radio>
                                                    </Col>
                                                    <Col className="flex items-center">
                                                        <img className="px-2" src={config.url.IMAGE_BASE_URL + 'app/cod.webp'} alt="" style={{ height: 40, width: 70, objectFit:'contain', alignItems: 'center' }} />
                                                    </Col>
                                                </Row>
                                                <Row className={value == 'RAZOR_PAY' ? "bg-green-100" : ""}  justify="space-between">
                                                    <Col className="flex items-center px-3">
                                                        <Radio value={'RAZOR_PAY'} style={{ justifyContent: 'center' }}>{PAYMENTMODES.RAZOR_PAY}</Radio>
                                                    </Col>
                                                    <Col className="flex items-center">
                                                        <img className="px-2" src={config.url.IMAGE_BASE_URL + 'app/Razorpay_logo.webp'} alt="" style={{ height: 40, width: 70, objectFit: 'contain', alignItems: 'center' }} />
                                                    </Col>
                                                </Row>
                                            </Radio.Group>
                                        </Row>
                                    </div> */}
                                    <div className="" style={{ height: '2rem' }}></div>
                                    {
                                        !(checkout.getIn(['error_code'])) ?
                                            <Row justify="space-between">

                                                < Button className=" col hover:bg-red-500 hover:text-white" onClick={() => onCancelOrder(checkout.getIn(['order_id'], null))} danger htmlType="submit">
                                                    Cancel
                                                </Button>
                                                < Button className="col" onClick={() => onPlaceOrder(checkout)} type="primary" htmlType="submit">
                                                    Place Order
                                                </Button>
                                            </Row> :
                                            null
                                    }

                                </>

                        }
                    </Col>
                </Row>
            </div >

        </>
    )
}


export default Cart


const CartItemGroup = ({ item, add2Cart }) => {
    return <Col span={24} className="p-3 border border-1 rounded " style={{ borderColor: 'rgb(28 159 71 / 46%)' }}>
        <div className="flex justify-between flex-wrap">
            <Title level={4} >{item.get('name')}</Title>
            <div className="text-right leading-6">
                {
                    item.get('delivery_fee') ?
                        <Text>{item.get('delivery_fee', 0) > 0 ? <>Delivery Free: <b>₹{item.get('delivery_fee')}</b></> : <b>Free Deivery</b>}</Text> :
                        null
                }
                {
                    item.get('total') ?
                        <Text><br />Total: <b>₹{item.get('total')}</b></Text> :
                        null
                }
            </div>
        </div>

        {item.get('note') ? <div className="text-red-500 mb-4 pl-1 font-semibold ">{item.get('note')}</div> : null}
        <Divider className="mt-1" />
        {
            item.getIn(['cart_items'])?.map((item, index) => {
                return <CartItem
                    key={index}
                    {...{ item, add2Cart }}
                />
            })
        }
    </Col>
}


const CartItem = (props) => {

    const { item, add2Cart } = props


    return <Link to={'/variantproducts/' + item.get('cart_item_variant_id') + '-' + item.getIn(['variant', 'variant_name'])}>
        <List.Item
            // key={index}
            className='hover:bg-green-100 rounded cursor-pointer px-4 __cart_item'
        >
            <List.Item.Meta
                avatar={
                    <img className='rounded-md shadow-md'
                        style={{ height: 100, width: 100, objectFit: 'contain' }}
                        src={item.getIn(['variant', 'images', 0, 'variant_images_name']) ?? item.getIn(['variant', 'product', 'product_image'])}
                    />
                }
                title={<span>{item.getIn(['variant', 'product', 'product_name'])}<br />{item.getIn(['variant', 'variant_name'])}</span>}
                // description={}
                description={
                    <Row justify="space-between flex-col lg:flex-row lg:items-center" >

                        {item.getIn(['variant', 'product', 'fresh_batches', 'batch_sale_price'], null) ?
                            <span className='text-lg text-green-600'>
                                <span>
                                    ₹{item.getIn(['cart_item_per_quantity_rate'], null)}
                                    {
                                        item.getIn(['cart_item_per_quantity_rate'], null) != item.getIn(['variant', 'product', 'fresh_batches', 'batch_sale_price'], null) ?
                                            <span style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}
                                                className='px-3 text-slate-500'>
                                                {`₹${(item.getIn(['variant', 'product', 'fresh_batches', 'batch_sale_price'], null))}`}
                                            </span>
                                            : null
                                    }
                                </span>




                                {item.get('error') ? <span className="text-red-600 text-sm font-medium"><br />{item.get('error')}</span> : null}
                            </span> : <span className='text-red-700'>Out of Stock</span>}

                        <div className="flex justify-between items-center mt-1" style={{ width: '10rem' }}>
                            <Button block className='card-shadow-xs __add_to_cart_button reverse minus'
                                icon={item.getIn(['cart_item_quantity'], 0) === 1 ? <DeleteFilled /> : <MinusOutlined />}
                                onClick={(e) => {
                                    e.preventDefault()
                                    add2Cart(item.getIn(['cart_item_batch_id']), item.getIn(['cart_item_quantity'], 0) > 0 ? item.getIn(['cart_item_quantity'], 0) - 1 : 0,
                                        item.getIn(['cart_item_id'], 0))
                                }} />
                            {item.getIn(['cart_item_quantity'], 0)}
                            <Button
                                block
                                className='card-shadow-xs __add_to_cart_button reverse'
                                icon={<PlusOutlined />}
                                onClick={(e) => {
                                    e.preventDefault()
                                    add2Cart(item.getIn(['cart_item_batch_id']), item.getIn(['cart_item_quantity'], 0) + 1)
                                }} />
                        </div>
                    </Row>
                }
            />
        </List.Item>
    </Link>
}