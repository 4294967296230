import { Layout, Row, Col, Input, Tooltip } from 'antd';
import { InfoCircleOutlined, InstagramFilled, FacebookFilled, YoutubeFilled } from '@ant-design/icons';
import config from '../config/constants';
import Title from 'antd/lib/typography/Title';
const { Footer } = Layout;


const AbeFooter = () => {

    let imgUrl = config.url.IMAGE_BASE_URL
    return (
        <Footer className='bg-slate-100 px-3'>
            <Row gutter={[16, 16]}>
                <Col span={12} className='flex flex-col items-center'>

                    {/* <Row> */}
                    <img
                        alt='logo'
                        width={250}
                        src={imgUrl + 'client/logo.png'}
                    />
                    {/* </Row> */}
                    <div className="" style={{ height: 10 }}></div>
                    <Row gutter={[10, 10]} justify='center' >
                        <Col>
                            <a href={config.PLAY_STORE} target={'_blank'}>
                                <img
                                    alt='playstore_icon'
                                    width={120}
                                    height={40}
                                    src={imgUrl + '/app/playstore.png'}
                                />
                            </a>
                        </Col>
                        <Col>
                            <a href={config.APP_STORE} target={'_blank'}>
                                <img
                                    alt='appstore_icon'
                                    width={120}
                                    height={40}
                                    src={imgUrl + '/app/appstore.png'}
                                />
                            </a>
                        </Col>

                    </Row>
                    <div className="" style={{ height: 10 }}></div>
                    <Row>
                        <Title level={5} className=' mt-2'>Follow Us</Title>
                    </Row>
                    <div className="" style={{ height: 10 }}></div>
                    <Row gutter={[10, 10]}>
                        <Col>
                            <a href='https://www.facebook.com/abemartindia' target={'_blank'}>
                                <FacebookFilled style={{ fontSize: 30 }} />
                            </a>
                        </Col>
                        <Col>
                            <a href='https://www.instagram.com/abemartindia' target={'_blank'}>
                                <InstagramFilled style={{ fontSize: 30 }} />
                            </a>
                        </Col>
                        <Col>
                            <a href='https://www.youtube.com/@AbeMart' target={'_blank'}>
                                <YoutubeFilled style={{ fontSize: 30 }} />
                            </a>
                        </Col>
                    </Row>

                </Col>


                <Col span={12}>

                    <Row className='mx-0' gutter={[12]}>

                        <Col md={{ span: 8 }} >
                            <Row>
                                <Title level={5} className='font-bold mt-2'>Know Us</Title>
                            </Row>
                            <Row>
                                <p><a href="/page/about-us">About Us</a></p>
                            </Row>
                            <Row>
                                <p><a href="https://blog.abemart.in/" target='__blank'>Blog</a></p>
                            </Row>
                            <Row>
                                <p><a href="/page/feedback">Feedback</a></p>
                            </Row>
                        
                        </Col>
                        <Col md={{ span: 8 }} >
                            <Row>
                                <Title level={5} className='font-bold mt-2'>Policies</Title>
                            </Row>
                            <Row>
                                <p><a href="/page/terms-&-conditions"> Terms & Conditions</a></p>
                            </Row>
                            <Row>
                                <p><a href="/page/return-policy"> Return Policy</a></p>
                            </Row>
                            <Row>
                                <p><a href="/page/privacy-policy">Privacy Policy</a></p>
                            </Row>
                            <Row>
                                <p><a href="/page/shipping-policy">Shipping Policy</a></p>
                            </Row>
                        </Col>

                        <Col md={{ span: 8 }} >
                            <Row>
                                <Title level={5} className='font-bold mt-2'>Contact</Title>
                            </Row>
                            <Row>
                                <p><a href="/page/contact-us">Contact Us</a></p>
                            </Row>
                                    {/* <Row>
                                <p>Grocery Store Locations</p>
                            </Row> */}
                                    {/* <div className="" style={{ height: 15 }}></div>
                            <Row>
                                <h3>Subscribe to us</h3>
                            </Row> */}
                                    {/* <Row>
                                <Input
                                    placeholder="Enter your Email"
                                    suffix={
                                        <Tooltip title="Extra information">
                                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    }
                                />
                            </Row> */}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Footer >
    );
}

export default AbeFooter;