import { useCallback, useEffect, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import I from 'immutable';
import List from "antd/lib/list";
import { useDispatch } from 'react-redux';
import { Link, } from 'react-router-dom'
import { asImmutable } from '../helpers/utils';
import AppActionCreators from '../store/app/AppActionCreators';
import { Card, Col, Collapse, Image, Row } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import config from '../config/constants';



const Categories = () => {

    const [error, setError] = useState()


    const [items, setItems] = useState(I.List())

    document.title = "Categories"


    const dispatch = useDispatch()

    const fetchData = useCallback(() => {

        dispatch(AppActionCreators.requestFetchCategoryList(config.DEFAULT_MART,(res, e) => {
            if (res) {
                let obj = {}
                let r = asImmutable(res)
                r.forEach(x => {
                    if (x.get('category_parent')) {
                        if (obj[x.get('category_parent')]) {
                            obj[x.get('category_parent')].push(x)
                        } else {
                            obj[x.get('category_parent')] = [x]
                        }
                    }
                })
                let t = r.filter(x => x.get('category_parent') == null)
                t = t.map(x => {
                    return x.set('data', asImmutable(obj[x.get('category_id')] ?? I.List()))
                })
                setItems(t)
                // let tt = page == 1 ? asImmutable(res.data) : items.push(...asImmutable(res.data))
                // setItems(asImmutable(res))
                // setTotal(0)
                // // setTotal(res.total)
                // // setHasMore(res.current_page <= res.last_page)
                // setHasMore(false)
                // setloading(false)

                // setSearchTitle(res.offer_name)
            } else {
                setError(e.message ?? 'Error')
            }
        }))
    }, [])


    useEffect(() => {
        fetchData()
    }, [])



    return (<>
        <Content className="site-layout" >

            {error ?
                <div className='m-1 md:m-5 p-0 md:p-5 text-center flex justify-center items-center' style={{ minHeight: 300 }}>
                    <Title
                        type="error"
                        level={2}
                        className="text-center w-100 uppercase text-3xl lg:text-5xl leading-normal" style={{ maxWidth: 500 }}> {error} </Title>
                </div>
                :
                <div
                    className="container __content_shadow bg-white py-5 my-6  flex flex-col flex-wrap"
                    style={{ height: '100%' }}>

                    <Title
                        type="success"
                        level={2}
                        className="text-center w-100">Categories</Title>




                    <div className='container px-3 flex-col px-2 lg:px-5'>


                        <Collapse
                            bordered={false}
                            expandIconPosition="right"
                            defaultActiveKey={['1']}
                            className='__category_collapse overflow-hidden mb-4'
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            style={{
                                background: 'white',
                                border: '1px solid #e9e9e9',
                                borderRadius: 10,
                                padding: 0,
                                margin: 0
                            }}

                        // items={getItems(panelStyle)}
                        >

                            {
                                items?.map(x =>
                                    <Collapse.Panel
                                        key={x.get('category_id')}
                                        header={<div className='items-center flex' >
                                            <Image
                                                preview={false}
                                                src={x.get('category_image')}
                                                width={64}
                                                height={64}

                                            />
                                            <Link to={{
                                                pathname: "/search",
                                                search: "?title=" + encodeURIComponent(x.get('category_name')) + "&mode=category&id=" + x.get('category_id')

                                            }} >
                                                <span className='ml-3'> {x.get('category_name')}</span>
                                            </Link>
                                        </div>}
                                    >

                                        <Row className=' flex-wrap mx-0 py-3 px-2 lg:px-5 ' gutter={[12, 12]} style={{ backgroundColor: '#f0f8eb' }}>

                                            {x.get('data', I.List()).isEmpty() ? <div className='pl-5 text-center'>No Items Found</div> : x.get('data')?.map(x1 => {
                                                return <Col key={x1.get('category_id')} >
                                                    <Link to={{
                                                        pathname: "/search",
                                                        search: "?title=" + encodeURIComponent(x1.get('category_name')) + "&mode=category&id=" + x1.get('category_id')

                                                    }} 
                                                    className='flex'>
                                                        <Card
                                                            bordered
                                                            style={{ borderRadius: 20 }}
                                                            bodyStyle={{ padding: '5px 15px' }}
                                                        >
                                                            {x1.get('category_name')}
                                                        </Card>
                                                    </Link>
                                                </Col>
                                            })}
                                        </Row>
                                    </Collapse.Panel>)
                            }
                        </Collapse>

                    </div>

                </div>
            }
        </Content >
    </>)
}
export default Categories