import {  all } from 'redux-saga/effects'

import AppSaga from './app/AppSaga'
// import CommSaga from '../pages/community/store/reducers/AppSaga'

export default function* rootSaga() {
  yield all([
    AppSaga(),
    // CommSaga()
    // EventSaga(),
  ])
}