import React, { Suspense, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'

// import "antd/dist/antd.css";
// import "antd/dist/antd.less";

import "./styles/styles.less";


import AppLoader from './common/AppLoader'
import { ToastContainer } from 'react-toastify';


import { Layout, Modal, Form, Row, Input, Button, Select, } from "antd";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { getToken, clearCookies, setCookies, getCookie } from "./helpers/utils";
import Navbar from "./components/navbar";
import AbeFooter from "./components/footer";
import AppActionCreators from "./store/app/AppActionCreators";
import Loader from "./widgets/loader/loader";
import I from 'immutable'
import Cart from "./pages/profile/Cart";
import { asImmutable } from "./helpers/utils";
import config from "./config/constants";
import Offers from "./pages/Offers";
import Categories from "./pages/Categories";
import DayFresh from "./pages/DayFresh";
import OrderPlaced from "./pages/profile/orders/orders-placed";

const SearchResults = React.lazy(() => import("./pages/SearchResults"));
const Profile = React.lazy(() => import("./pages/Account"));
const Home = React.lazy(() => import("./pages/Home"));
const ProductDetails = React.lazy(() => import("./pages/ProductDetails"));
const StaticPage = React.lazy(() => import("./pages/StaticPage"));
const { Option } = Select;



function App() {

  const location = useLocation();
  const path = location.pathname

  // const [loaded, setLoaded] = useState(false)


  useEffect(() => {
    // execute on location change
    // console.log('loaded', loaded);
    // if (loaded) {//} != location.pathname){
    // console.log('Location changed!', location.pathname);
    pushPageView()
    // }
    // setLoaded(true)
  }, [location]);


  const pushPageView = () => {

    setTimeout(() => {
      // console.log('Location changed!', document.title);
      window.dataLayer.push({
        event: 'page_view',
        page_location: window.location,
        page_title: document.title
        // page: {
        //   page_location: window.location,
        //   page_title: document.title
        // }
      });

      // window.gtag('event', 'page_view', {
      //   page_location: window.location,
      //   page_title: document.title
      // });
    }, 500);
  }


  return (
    <Layout style={{ minHeight: '100vh', }}>
      <ToastContainer />
      <AppLoader />
      <AppC />
    </Layout>
  );
}


function AppC() {

  const mapState = useCallback(({ app }) => ({
    profile: app.getIn(['profile'], I.Map()),
    showPinCode: app.getIn(['showPinCode'], I.Map())
  }), [])

  const { profile, showPinCode } = useSelector(mapState)

  const dispatch = useDispatch();


  // const [isGuest, setIsGuest] = useState(false);

  // useEffect(() => {
  // if (profile && profile.get('isGuest')) {
  // setIsGuest(profile.getIn(['isGuest'], false))
  // }
  // }, [profile])


  const [isPinModalVisible, setIsPinModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [loginInfo, setLoginInfo] = useState();

  const [zipcodes, setZipcodes] = useState(I.List());
  const [zipType, setZipType] = useState();




  useEffect(() => {
    if ((!getCookie('pincode') && config.IS_PINCODE_ENABLED) || showPinCode) {
      console.log('pin');
      setIsPinModalVisible(true);
    }

  }, [profile, showPinCode])




  const getZipCodes = useCallback(() => {
    if (isPinModalVisible && !zipType) {
      dispatch(AppActionCreators.requestFetchZipCodes(null, (res) => {
        if (res) {
          let r = asImmutable(res)
          setZipcodes(r.get('data'))
          setZipType(r.get('type'))
        }
      }))
    }
  }, [isPinModalVisible])



  const setPinCode = useCallback((pincode) => {
    dispatch(AppActionCreators.setPinCode(pincode))
  }, [dispatch])


  const guestLogin = useCallback(() => {
    dispatch(AppActionCreators.requestCreateGuestLogin((res) => {
      if (res) {
        // console.log(res);
      }
    }))
  }, [dispatch])


  const fetchData = useCallback(() => {
    if (!getCookie('pincode') && config.IS_PINCODE_ENABLED) {
      // console.log('pin');
      setIsPinModalVisible(true);
    } else {
      setPinCode(getCookie('pincode'))
    }

    if (!getToken()) {
      guestLogin()
    } else {
      // dispatch(AppActionCreators.requestFetchProfileData((res, code) => {
      dispatch(AppActionCreators.requestFetchProfileDataAll((res, code) => {
        // console.log(res, code)
        if (res) {
          setLoginInfo(res)
        } else if (code === 401) {
          clearCookies()
          guestLogin()
        } else {
          dispatch(AppActionCreators.requestFetchProfileData())
        }
      }))
    }

    // else {
    // setPinCode(getCookie('pincode'))
    // }

  }, [dispatch])

  const [loginPanelVisible, setLoginPanelVisible] = useState(false)

  const handleOk = (values) => {
    console.log('PinForm')
    setIsPinModalVisible(false);
    setCookies("pincode", values.pincode);
    setPinCode(values.pincode)

  };

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    getZipCodes()
  }, [isPinModalVisible])


  return (
    // <Router forceRefresh={true}>
    <Layout style={{ justifyContent: 'space-between' }}>
      <Navbar {...{ loginInfo, setIsPinModalVisible, loginPanelVisible, setLoginPanelVisible }}></Navbar>
      <Suspense fallback={<AppLoader />}>

        {profile ?
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>

              }
            />
            <Route
              path="/home"
              element={<Navigate to='/' replace />}
            />

            <Route
              path="/page/:key"
              element={
                <Suspense fallback={<Loader />}>
                  <StaticPage />
                </Suspense>
              }
            />

            <Route
              path="/account/ordersPlaced"
              element={
                <Suspense fallback={<Loader />}>
                  <OrderPlaced />
                </Suspense>

              }
            />

            <Route
              path="/account/*"
              element={
                (profile.get('isGuest') ?
                  <Navigate to='/' /> :
                  <Suspense fallback={<Loader />}>
                    <Profile />
                  </Suspense>)

              }
            >
            </Route>

            <Route
              path="/cart"
              element={
                <Suspense fallback={<Loader />}>
                  <Cart {...{ setLoginPanelVisible }} />
                </Suspense>

              }
            />


            <Route
              path="/categories"
              element={
                <Suspense fallback={<Loader />}>
                  <Categories />
                </Suspense>
              }
            />
            <Route
              path="/DayFresh"
              element={
                <Suspense fallback={<Loader />}>
                  <DayFresh />
                </Suspense>
              }
            />
            <Route
              path="/search"
              element={
                <Suspense fallback={<Loader />}>
                  <SearchResults />
                </Suspense>
              }
            />

            <Route
              path="/offers/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <Offers />
                </Suspense>
              }
            />

            <Route
              path="/variantproducts/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <ProductDetails />
                </Suspense>
              }

            />

          </Routes> :
          null}
      </Suspense>

      <Modal title={null} footer={null} visible={isPinModalVisible} destroyOnClose={true}
        onCancel={() => {
          if (getCookie('pincode')) {
            setIsPinModalVisible(false)
          }
        }}>
        <Form onFinish={handleOk} onCancel={null}>
          <Row>
            {
              zipType ? (zipType == "10" ?
                <Form.Item
                  name="pincode"
                  label="Please enter your pincode"
                  rules={[{ required: true, message: 'Please input your pincode!' }, {
                    message: "Pincode must be digits.",
                    pattern: new RegExp(/^[0-9]+$/)
                  }, { min: 6, max: 6, message: 'Pincode must be 6 digits.' }]}
                >
                  <Select placeholder="Please select your Pincode">
                    {zipcodes.map((zipcodes, index) => {
                      return <Option key={index} value={zipcodes.get('pincode')}>{zipcodes.get('pincode')}</Option>
                    })}
                  </Select>
                </Form.Item> :
                <Form.Item
                  name="pincode"
                  label="Please enter your pincode"
                  rules={[{ required: true, message: 'Please input your pincode!' }, {
                    message: "Pincode must be digits.",
                    pattern: new RegExp(/^[0-9]+$/)
                  }, { min: 6, max: 6, message: 'Pincode must be 6 digits.' }]}
                >
                  <Input
                    tabIndex="1"
                    type="text"
                    className="pincodeInput"
                    maxLength='6'
                  />

                </Form.Item>
              ) : null}


          </Row>
          <Row justify='center' >
            <Form.Item className="mb-2 ml-5">
              <Button tabIndex="2" loading={confirmLoading} type='primary' htmlType="submit" style={{ minWidth: 120 }}>
                Set Pincode
              </Button>
            </Form.Item>

          </Row>

          {/* {!(profile && profile.get('phone')) ? <>

            <Row justify='center' className="mb-2 ml-5">
              Or
            </Row>

            <Row justify='center' >
              <Form.Item className="ml-5">
                <Button tabIndex="2" type='primary' onClick={() => {
                  setIsPinModalVisible(false)
                  setLoginPanelVisible(true)
                }} style={{ minWidth: 120 }}>
                  Login
                </Button>
              </Form.Item>
            </Row>

          </> : null
          } */}
        </Form>
      </Modal>
      <AbeFooter></AbeFooter>
    </Layout >

    // </Router>
  );
}

export default App;
