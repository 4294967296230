import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import './helpers/logger'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { BrowserRouter } from "react-router-dom";




import store, { history } from './store/store'

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store} >
    {/* <ConnectedRouter history={history}> */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </ConnectedRouter> */}
  </Provider>
  //  </React.StrictMode>
  , document.getElementById('root')
);


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
