import { createElement, useCallback, useEffect, useState } from 'react';

import { asImmutable } from "../../../helpers/utils"
import AppActionCreators from "../../../store/app/AppActionCreators"
import { useDispatch } from "react-redux"
import I from 'immutable'
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"

import { List, Typography, Avatar, Space, Card, Modal, Row, Col, Divider } from 'antd'
import moment from 'moment'
import { ORDER } from '../../../config/strings';

const OrderPlaced = (props) => {

    document.title = "Orders"

    const { Title, Text } = Typography

    const [selectedOrder, setSelectedOrder] = useState(I.Map())
    const [deliveryMethodListData, setDeliveryMethodListData] = useState(I.List());
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(I.Map())

    // const [selectedOrder, setSelectedOrder] = useState(I.Map())
    // const [pageCount, setPageCount] = useState(null)
    const dispatch = useDispatch()


    const [searchParams] = useSearchParams();
    const [isModalVisible, setIsModalVisible] = useState(false)



    const showProductModal = (items) => {
        setIsModalVisible(true)
        setSelectedDeliveryMethod(items)
    }

    const imageList = (items) => {

        let imageLimit = 2
        let li = []
        for (let index = 0; index < items.size && imageLimit >= 0; index++, imageLimit--) {
            if (items.getIn([index, 'order_item_image'])) {
                li.push(<img key={index}
                    className='rounded-md shadow-md object-contain absolute'
                    style={{
                        height: 50, width: 50, top: `calc(${index} * 10%)`, left: `calc(${index} * 10%)`,
                        transform: 'translate(50%,50%)'
                    }}
                    src={items.getIn([index, 'order_item_image'])} />)
            }
        }

        return li
    }


    // const navigate = useNavigate()


    // const fetchData = useCallback((pageno) => {
    //     dispatch(AppActionCreators.requestFetchOrdersList(pageno, (res) => {
    //         if (res) {
    //             setPageCount(res.last_page)
    //             setOrders(asImmutable(res.data));
    //         }
    //     }))
    // }, [])

    // const viewOrder = useCallback((order) => {
    //     setSelectedOrder(order)
    //     navigate("/account/orders/" + order.get('order_id') + "/view")
    // })

    //

    const fetchData = useCallback((orderId, orderNo) => {

        dispatch(AppActionCreators.requestFetchOrder(orderId, (res) => {
            if (res) {

                var deliveryMethods = asImmutable(res).get('delivery_methods')
                const tempList = [
                    // res
                ]
                deliveryMethods.forEach((deliveryMethod, index) => {
                    tempList.push({
                        key: index,
                        orderItems: deliveryMethod.get('items'),
                        orderItemCount: deliveryMethod.get('items', []).size,
                        orderItemAmount: deliveryMethod.get('price'),
                        expectedDeliveryDate: deliveryMethod.get('expected_date_of_delivery'),
                        timeSlot:
                            deliveryMethod.getIn(['delivery_time_details', 'delivery_time_name']),
                    });
                })
                setDeliveryMethodListData(asImmutable(tempList))
                setSelectedOrder(asImmutable(res))
            }
        }))

    }, [])

    useEffect(() => {
        fetchData(searchParams.get('order_id'), searchParams.get('order_no'))
    }, [searchParams])

    return selectedOrder ?
        <>

            <div className='container bg-white rounded flex flex-col p-3 lg:px-5 m-3 lg:m-5'>

                <Row >

                    <Col sm={24} lg={12} className='my-2'>
                        <Title level={4} className="flex">
                            Order No:
                            <span className='font-bold flex flex-row' style={{ flex: 1 }}>#{selectedOrder.get('order_no')}
                            </span>
                        </Title>

                    </Col>

                    <Col sm={24} lg={12} className=' text-end text-right mb-2'>
                        <span className='text-muted font-medium text-base'>
                            {selectedOrder.get('created_at') ? moment(selectedOrder.get('created_at')).format('MMM DD, YYYY hh:mm a') : ''}
                        </span>
                    </Col>
                </Row>

                <Divider />



                {selectedOrder ? <>

                    <Row className='mb-3' >
                        <Title level={4}> Order Status: <span
                            style={{ fontWeight: 'bold', color: selectedOrder.getIn(['status', 'status_settings', 'status_mode']) == ORDER.CANCELLED ? "red" : "inherit" }}>
                            {selectedOrder.getIn(['status', 'order_status_name'])}
                        </span>
                        </Title>
                        <Col span={24}>
                            {
                                selectedOrder.getIn(['shipping_address'], null) ?
                                    <Card style={{ width: 300 }}>
                                        <Title level={4}> Shipping Address: </Title>

                                        <Title level={5} style={{ marginBlock: 0 }}>
                                            {selectedOrder.getIn(['shipping_address', 'address_name'])}
                                        </Title>
                                        <Title level={5} style={{ marginBlock: 0 }}>
                                            {selectedOrder.getIn(['shipping_address', 'address_building'])}
                                        </Title>
                                        <Title level={5} style={{ marginBlock: 0 }}>
                                            {selectedOrder.getIn(['shipping_address', 'address_road'])}
                                        </Title>
                                        <Title level={5} style={{ marginBlock: 0 }}>
                                            {selectedOrder.getIn(['shipping_address', 'address_city'])}
                                        </Title>
                                        {/* <Title level={5} style={{ marginBlock: 0 }}> 
                            {selectedOrder.getIn(['shipping_address', 'address_state'])}
                        </Title> */}
                                        <Title level={5} style={{ marginBlock: 0 }}>
                                            {selectedOrder.getIn(['shipping_address', 'address_zip'])}
                                        </Title>
                                    </Card> : null


                            }
                        </Col>
                    </Row>

                    {/* {selectedOrder.getIn(['payment_type']) ?
        <Title level={4}> Payment Mode: <span className='font-bold pl-3'>
            {selectedOrder.getIn(['payment_method', 'payment_method_title'])}
        </span>
        </Title> : null} */}
                    {selectedOrder.getIn(['order_items_sum_order_item_net_rate']) ?
                        <Title level={4}> Total Amount: <span className='font-bold'>
                            ₹{selectedOrder.getIn(['order_items_sum_order_item_net_rate'])}</span>
                        </Title> : null
                    }


                    {selectedOrder.getIn(['voucher', 'voucher_value']) ?
                        <Title level={4} type='success'> voucher applied: <span className='font-bold'>
                            ₹{parseFloat(selectedOrder.getIn(['voucher', 'voucher_value']))}</span>
                        </Title> : null
                    }

                    <div style={{ marginBlock: 20 }}>
                        <Title level={4} style={{ marginBlock: 0 }}>
                            Ordered Items
                        </Title>
                    </div>
                    <List
                        itemLayout="vertical"
                        dataSource={deliveryMethodListData}
                        renderItem={deliveryMethod => (
                            <>
                                <List.Item
                                    key={deliveryMethod.get('key')}
                                    className='hover:bg-green-100 rounded cursor-pointer'
                                    onClick={() => showProductModal(deliveryMethod.get('orderItems'))}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <div style={{ position: 'relative', height: 100, width: 100, display: 'grid' }}>
                                                {
                                                    imageList(deliveryMethod.get('orderItems'))
                                                }
                                            </div>
                                        }
                                        title={`₹${deliveryMethod.get('orderItemAmount')}`}
                                        description={
                                            <div>
                                                <Title level={5} style={{ marginBlock: 0 }}>
                                                    <span className='text-green-600'>
                                                        {deliveryMethod.get('orderItemCount')}{+ deliveryMethod.get('orderItemCount') > 1 ? ' items' : ' item'}
                                                    </span>
                                                    {selectedOrder.getIn(['status', 'status_settings', 'status_mode']) != ORDER.CANCELLED ? <>
                                                        {moment(deliveryMethod.get('expectedDeliveryDate')).isBefore(Date()) ? '' : ' to be '} delivered on
                                                        <br />
                                                        <Text className='text-green-600' style={{ marginBlock: 0 }}>
                                                            {deliveryMethod.get('expectedDeliveryDate')}
                                                            <br />
                                                            {deliveryMethod.get('timeSlot') ? `| ${deliveryMethod.get('timeSlot')}` : ''}
                                                        </Text></> : null}
                                                </Title>
                                            </div>
                                        }
                                    />
                                </List.Item>
                            </>
                        )}
                    />
                </> : null}
                <Modal
                    title="Ordered Products"
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelText="Close" centered
                    bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
                    destroyOnClose visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}>
                    <List
                        itemLayout="vertical"
                        dataSource={selectedDeliveryMethod}
                        renderItem={(orderItem, index) =>
                            <List.Item
                                key={index}
                                className='hover:bg-green-100 rounded cursor-pointer px-4'
                            >
                                <List.Item.Meta
                                    avatar={<div className="relative" key={index}>
                                        <img
                                            className='rounded-md shadow-md overflow-hidden'
                                            style={{ height: 100, width: 100, objectFit: 'contain' }}
                                            src={orderItem.getIn(['order_item_image'])} />

                                        {orderItem.getIn(['order_item_quantity']) != 1 ? <span
                                            className="__count"
                                            style={{
                                                bottom: -10, right: -5,
                                            }} >{orderItem.getIn(['order_item_quantity'])}
                                        </span> : null}
                                    </div>
                                    }
                                    title={<>{orderItem.getIn(['order_item_product_title'])}<br />{orderItem.getIn(['order_item_variant_name'])}</>}
                                    description={
                                        <span className='text-lg text-green-600'>₹ {orderItem.getIn(['order_item_net_rate'], null)}</span>
                                    }
                                />
                            </List.Item>
                        }


                    />



                </Modal>

            </div>
        </>
        : null

}
export default OrderPlaced