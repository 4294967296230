export default {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',

  SET_CART: 'SET_CART',
  SET_WISH_LIST: 'SET_WISH_LIST',


  //ABEMART

  SET_PROFILE: 'SET_PROFILE',
  SET_PROFILE_DATA_ALL: 'SET_PROFILE_DATA_ALL',
  SET_ADDRESS_LIST: 'SET_ADDRESS_LIST',
  SET_PIN_CODE: 'SET_PIN_CODE',
  SET_SHOW_PIN_CODE: 'SET_SHOW_PIN_CODE',

  REQUEST_FETCH_BANNER: 'REQUEST_FETCH_BANNER',
  REQUEST_FETCH_CATEGORY_LIST: 'REQUEST_FETCH_CATEGORY_LIST',
  REQUEST_FETCH_DELIVERY_SLOTS: 'REQUEST_FETCH_DELIVERY_SLOTS',
  REQUEST_FETCH_OFFER_LIST: 'REQUEST_FETCH_OFFER_LIST',
  REQUEST_FETCH_SEARCH_LIST: 'REQUEST_FETCH_SEARCH_LIST',
  REQUEST_CREATE_GUEST_LOGIN: 'REQUEST_CREATE_GUEST_LOGIN',
  REQUEST_FETCH_OTP_DATA: 'REQUEST_FETCH_OTP_DATA',
  REQUEST_VALIDATE_OTP_DATA: 'VALIDATE_OTP_DATA',
  REQUEST_CREATE_NAME_EMAIL: 'REQUEST_CREATE_NAME_EMAIL',
  REQUEST_FETCH_PROFILE_DATA: 'REQUEST_FETCH_PROFILE_DATA',
  REQUEST_FETCH_PROFILE_DATA_ALL: 'REQUEST_FETCH_PROFILE_DATA_ALL',
  REQUEST_FETCH_SEARCH_RESULT: 'REQUEST_FETCH_SEARCH_RESULT',
  REQUEST_FETCH_SEARCH_PRODUCT_RESULT: 'REQUEST_FETCH_SEARCH_PRODUCT_RESULT',
  REQUEST_FETCH_OFFER: 'REQUEST_FETCH_OFFER',
  REQUEST_FETCH_PRODUCT_DETAILS: 'REQUEST_FETCH_PRODUCT_DETAILS',
  REQUEST_UPDATE_PROFILE: 'UPDATE_PROFILE',
  REQUEST_VERIFY_EMAIL: 'REQUEST_VERIFY_EMAIL',
  REQUEST_FETCH_ORDERS_LIST: 'REQUEST_FETCH_ORDERS_LIST',
  REQUEST_FETCH_ORDER: 'REQUEST_FETCH_ORDERS',
  REQUEST_FETCH_REVIEWS: 'REQUEST_FETCH_REVIEWS',
  REQUEST_SUBMIT_REVIEWS: 'REQUEST_SUBMIT_REVIEWS',
  REQUEST_EDIT_REVIEWS: 'REQUEST_EDIT_REVIEWS',
  REQUEST_DELETE_REVIEWS: 'REQUEST_DELETE_REVIEWS',
  REQUEST_CANCEL_ORDER: 'REQUEST_CANCEL_ORDER',
  REQUEST_FETCH_ADDRESS_LIST: 'REQUEST_FETCH_ADDRESS_LIST',
  REQUEST_FETCH_ADDRESS: 'REQUEST_FETCH_ADDRESS',
  REQUEST_CREATE_OR_UPDATE_ADDRESS: 'REQUEST_CREATE_OR_UPDATE_ADDRESS',
  REQUEST_CREATE_OR_UPDATE_ADDRESS1: 'REQUEST_CREATE_OR_UPDATE_ADDRESS1',
  REQUEST_FETCH_STATES: 'REQUEST_FETCH_STATES',
  REQUEST_FETCH_ZIPCODES: 'REQUEST_FETCH_ZIPCODES',
  REQUEST_DELETE_ADDRESS: 'REQUEST_DELETE_ADDRESS',
  REQUEST_FETCH_CART: 'REQUEST_FETCH_CART',
  REQUEST_FETCH_CART_V2: 'REQUEST_FETCH_CART_V2',
  REQUEST_ADD_2_CART: 'REQUEST_ADD_2_CART',

  REQUEST_FETCH_WISH_LIST: 'REQUEST_FETCH_WISH_LIST',
  REQUEST_FETCH_AVAILABLE_TIME_SLOT: 'REQUEST_FETCH_AVAILABLE_TIME_SLOT',
  REQUEST_SET_TIME_SLOT: 'REQUEST_SET_TIME_SLOT',
  REQUEST_SET_PAYMENT_STATUS: 'REQUEST_SET_PAYMENT_STATUS',
  REQUEST_UPDATE_WISH_LIST: 'REQUEST_UPDATE_WISH_LIST',
  REQUEST_SET_CART_CHECKOUT: 'REQUEST_SET_CART_CHECKOUT',
  REQUEST_LOGOUT: 'REQUEST_LOGOUT',
  REQUEST_PAGE: 'REQUEST_PAGE',
  REQUEST_CHECK_VOUCHER: 'REQUEST_CHECK_VOUCHER',
  REQUEST_FETCH_DAY_FRESH_DELIVERY_SLOTS: 'REQUEST_FETCH_DAY_FRESH_DELIVERY_SLOTS',

}
