import I from 'immutable'

// import { getData } from 'country-list'


import AppActionTypes from './AppActionTypes'
// import { getSelectedEventIdFromLocalStorage, getTokenFromLocalStorage } from '../../helpers/StorageHelper'
import { asImmutable, getCookie, setCookies } from '../../helpers/utils'

const initialState = I.Map({
  initialLoad: true,
  showLoader: false,
  showPinCode: false,
  pincode: null,
  profile: null,
  cart: {},
  cart_details: null,
  cart_item_count: null,
  profile1: I.Map({
    isGuest: true
  })
  // showSaveButton: false,
  // backgroundSaveInProgress: false,
  // user: I.Map({
  //   // events: I.List(),
  //   // token: getTokenFromLocalStorage()
  // }),
})

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case AppActionTypes.SHOW_LOADER:
      return state.set('showLoader', true)
    case AppActionTypes.HIDE_LOADER:
      return state.set('showLoader', false)

    case AppActionTypes.SET_PROFILE_DATA_ALL: {
      let data = asImmutable(payload.data)
      // let wish = data.get('wish_list')
      let wish = data.get('wishlist')
      let cart = data.get('cart')
      // let profile = data.remove('wish_list').remove('cart')
      let profile = data.remove('wishlist').remove('cart')
      if (profile.getIn(['phone'])) {
        profile = profile.set('isGuest', false)
      } else {
        profile = profile.set('isGuest', true)
      }
      if (data.getIn(['address', 'address_zip']) && !getCookie("pincode")) {
        setCookies('pincode', data.getIn(['address', 'address_zip']))
      }

      let items = {}

      cart.getIn(['cart_items'], I.List()).map(x => {
        x.getIn(['cart_items'], I.List()).map(e => {
          items[e.get('cart_item_variant_id') + '_' + e.get('cart_item_batch_id')] = e.get('cart_item_quantity')
        });
      });


      return state.set('profile', profile)
        .set('cart', asImmutable(items))
        .set('cart_details', cart)
        .set('cart_item_count', cart.get('cart_item_count'))
        .set('wish', wish)
    }

    case AppActionTypes.SET_PROFILE:
      let data = asImmutable(payload.data)

      if (payload.phone) {
        data = data.set('phone', payload.phone)
      }
      if (data.get('token')) {
        setCookies('token', data.get('token'))
      }

      let clearPin = data.size === 0
      let isGuest = false

      if (data.getIn(['phone'])) {
        isGuest = false
      } else {
        isGuest = true
      }

      data = data.set('isGuest', isGuest)
      data = state.set('profile', data)

      if (clearPin) {
        data = state.remove('pincode')
      }
      
      if(isGuest){
        data = data.set('cart',I.Map()).remove('cart_details').remove('cart_item_count')
      }


      return data

    case AppActionTypes.SET_ADDRESS_LIST: {

      let s = state

      let da = asImmutable(payload.data)
      if (da.size > 0) {
        const defAddress = da.filter(x => x.get('is_default') == 10).first() || da.first()
        s = s.setIn(['profile', 'address'], defAddress)
        let p = defAddress.getIn(['address_zip'])
        if (p) {
          s = s.set('pincode', p)
          setCookies('pincode', p)
        }
      }

      return s.set('address', da)
    }
    case AppActionTypes.SET_PIN_CODE:
      return state.set('pincode', asImmutable(payload.pincode))
    case AppActionTypes.SET_SHOW_PIN_CODE:
      return state.set('showPinCode', asImmutable(payload.status))

    case AppActionTypes.SET_CART:

      var items = {}
      let s = asImmutable(payload.data)
      s.getIn(['cart_items'], I.List()).map(x => {
        x.getIn(['cart_items'], I.List()).map(e => {
          items[e.get('cart_item_variant_id') + '_' + e.get('cart_item_batch_id')] = e.get('cart_item_quantity')
        })
      })

      return state
        .set('cart', asImmutable(items))
        .set('cart_details', s)
        .set('cart_item_count', s.get('cart_item_count'))



    case AppActionTypes.SET_WISH_LIST:
      console.log("payloadddd", payload.data);
      return state.set('wish', asImmutable(payload.data))
    // return state.set('wish_list', asImmutable(payload.data))
    default:
      return state
  }
}
