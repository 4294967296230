import React, { useCallback, useState } from 'react';
import { Modal, Button, Input, Row, Col, Form, Popover, Typography } from 'antd'
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import I from 'immutable'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import AppActionCreators from '../store/app/AppActionCreators';
import { clearCookies, getCookie, getToken, setCookies } from '../helpers/utils';
import { Link } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import config from '../config/constants';

const { Text } = Typography

const otpStyle = {
    width: "3rem",
    height: "3rem",
    // margin: "0 1rem",
    fontSize: "1.5rem",
    borderRadius: 4,
    border: "1px solid rgba(0,0,0,0.3)",
    textAlign: 'center'
    
}


export default (props) => {

    const mapState = useCallback(({ app }) => ({
        pincode: app.get('pincode'),
        profile: app.getIn(['profile'], I.Map())
    }), [])

    const { pincode, profile } = useSelector(mapState)

    const { setIsPinModalVisible, loginPanelVisible, setLoginPanelVisible } = props

    const [confirmLoading, setConfirmLoading] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState()
    const [isNewUser, setIsNewUser] = useState(false)
    const [loginInfo, setLoginInfo] = useState();

    const [nameEmailForm] = useForm()




    // const [isModalClosable, setIsModalClosable] = useState(true)

    const [isPopoverVisible, setIsPopoverVisible] = useState(false)
    const hidePopover = () => {
        setIsPopoverVisible(false);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate()


    const showModal = () => {
        setLoginPanelVisible(true);
        hidePopover()
    };

    const handleOk = (values) => {
        setConfirmLoading(true);
        const otp = values.otp1 + values.otp2 + values.otp3 + values.otp4
        validateOtp(phoneNumber, otp)
    };

    const handleCancel = useCallback(() => {
        // if (isModalClosable) {
        setPhoneNumber()
        setLoginPanelVisible(false);

        if (!pincode && config.IS_PINCODE_ENABLED) {
            setIsPinModalVisible(true)
        }
        // }
    }, [pincode])
    const fetchOtp = useCallback((phone) => {
        dispatch(AppActionCreators.requestFetchOTPData(phone, (res) => {
            if (res) {
                // TOAST.info(res.data.otp)
                setPhoneNumber(phone)
                // setIsModalClosable(false)
                // console.log(res.data.otp);
            }
        }))
    })

    const guestLogin = useCallback(() => {
        dispatch(AppActionCreators.requestCreateGuestLogin((res) => {
            if (res) {
                // console.log(res);
            }
        }))
    }, [dispatch])

    const ProfileDataAll = useCallback(() => {
        dispatch(AppActionCreators.requestFetchProfileDataAll((res, code) => {
            // console.log('ProfileDataAll', res, code)
            if (res) {
                setLoginInfo(res)
            } else if (code === 401) {
                clearCookies()
                guestLogin()
            } else {
                dispatch(AppActionCreators.requestFetchProfileData())
            }
        }))
    })

    const validateOtp = useCallback((phone, otp) => {
        dispatch(AppActionCreators.requestValidateOTPData(phone, otp, (res) => {
            if (res) {
                if (res.data.type === "existing_user") {
                    setLoginPanelVisible(false);
                    setConfirmLoading(false);
                    ProfileDataAll();
                }
                if (res.data.type === "new_user") {
                    // setConfirmLoading(false);
                    setLoginPanelVisible(true)
                    // setIsModalClosable(false)
                    setIsNewUser(true)
                    ProfileDataAll();

                }
            } else {
                setConfirmLoading(false);
            }
        }))
    })
    const setUserName = useCallback((username, email) => {
        dispatch(AppActionCreators.requestCreateNameEmail(username, email, getCookie('phone'), (res, error) => {
            // console.log(res,error);
            if (res) {

                setLoginPanelVisible(false);
                setConfirmLoading(false);
                // setIsModalClosable(true)
                setIsNewUser(false)
            } else {

                let errors = error?.errors
                console.log(errors);
                let c = Object.keys(errors).map(x => {
                    return ({ name: x, errors: errors[x] })
                })
                console.log(c);
                nameEmailForm.setFields(c)
            }
        }))
    })
    const getUserName = (values) => {
        setUserName(values.customer_name, values.customer_email)
    }

    const getOtp = useCallback((values) => {
        fetchOtp(values.phone + " ".trim())
    })








    const profileMenu = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Link to="/account/profile" className='hover:text-green-400' onClick={hidePopover}>My Profile</Link>
            <a className='hover:text-green-400' onClick={confirmLogout}>Sign Out</a>
        </div>
    );

    const profileAction = () => {
        if (profile && profile.getIn(['phone'])) {
            return profileMenu
        } else {
            return <a className='hover:text-green-400' onClick={showModal}>Login</a>
        }
    }

    const handleVisibleChange = (visible) => {
        setIsPopoverVisible(visible)
    }

    function confirmLogout() {
        hidePopover()
        Modal.confirm({
            title: 'Sign Out',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to Sign Out?',
            onOk: () => signOutHandler(),
            okText: "Sign Out",
            cancelText: "Cancel",
        });
    }
    const signOutHandler = useCallback(() => {
        dispatch(AppActionCreators.requestLogout((res) => {
            if (res) {
                console.log(res);
                clearCookies()
                setPhoneNumber()
                if (!getToken()) {
                    dispatch(AppActionCreators.requestCreateGuestLogin((res) => {
                        if (res) {
                            console.log(res);
                        }
                    }))
                }
                navigate("/", { replace: true })
            }
        }))
    })


    return (
        <>
            <Popover
                content={profileAction}
                title={
                    profile ? <div>
                        {profile.getIn(['name'], 'Guest')}<br />
                        {profile.getIn(['phone']) ? <span className="text-xs text-slate-700">{profile.getIn(['phone'])} </span> : null}
                    </div> : "Profile"
                }
                placement="bottomRight"
                trigger="click"
                visible={isPopoverVisible}
                onVisibleChange={handleVisibleChange}
            >
                <Button shape="circle" icon={<UserOutlined />} onClick={profileAction} className='__btn_icon_login mr-1' >

                </Button>
                {
                    (profile?.getIn(['isGuest'], false) ?? true) ?
                        null :
                        <a href='#' className='text-gray-500'>{profile?.getIn(['name'], '')}</a>
                }
            </Popover>


            <Modal
                title='Login'
                visible={loginPanelVisible}
                // onOk={handleOk}
                // confirmLoading={confirmLoading}
                destroyOnClose
                onCancel={handleCancel}
                maskClosable={true}
                footer={null}
                className='__modal_login'
            >


                {!phoneNumber ?

                    <FormPhoneNumber {...{ getOtp }} /> :

                    !isNewUser ?
                        <FormOTP {...{ handleOk, confirmLoading, phoneNumber, fetchOtp }} />


                        : <Form
                            form={nameEmailForm}
                            layout='vertical'
                            onFinish={getUserName}>
                            <Row>
                                <Col span={16}>
                                    <Form.Item
                                        label='Name:'
                                        name="customer_name"
                                        rules={[{ required: true, message: 'Please input your name!' }]}
                                    >
                                        <Input type="text" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={16}>
                                    <Form.Item
                                        label="Email:"
                                        name="customer_email"
                                        rules={[{ required: true, message: 'Please input your email address!' }, {
                                            message: "Invalid Email",
                                            pattern: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
                                        }]}
                                    >
                                        <Input type="email" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='end'>
                                <Form.Item >
                                    <Button type='primary' htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Row>


                        </Form>}
            </Modal>
        </>
    );
}

const FormPhoneNumber = (props) => {

    return (<Form layout='vertical' onFinish={props.getOtp}>
        <Col span={16}>
            <Form.Item
                label={'Phone number'}
                name="phone"
                rules={[{ required: true, message: 'Please input your phone number!' }, {
                    message: "Phone number must be digits.",
                    pattern: new RegExp(/^[0-9]+$/)
                }, { min: 10, max: 10, message: 'Phone number must be 10 digits.' }]}
            >
                <Input type="tel" />
            </Form.Item>
            <Form.Item >
                <Button htmlType="submit">
                    Submit
                </Button>
            </Form.Item>

        </Col>

    </Form>)
}



const FormOTP = (props) => {

    const [otpForm] = useForm()

    const otpRules =
        [{ required: true, message: 'Required' },
            //  { pattern: new RegExp(/^[0-9]+$/) }
        ]


    const inputFocus = useCallback((e) => {
        if (e.key === "Delete" || e.key === "Backspace") {
            const next = e.target.tabIndex - 2;
            if (next > -1) {
                e.target.form.elements[next].focus()
            }
        } else {
            if (isNaN(e.target.value)) {
                otpForm.setFieldsValue({
                    [e.target.id]: ''
                })
            } else {
                const next = e.target.tabIndex;
                if (next < 4) {
                    e.target.form.elements[next].focus()
                    otpForm.setFieldsValue({
                        [e.target.form.elements[next].id]: ''
                    })
                }

            }
        }
    }, [otpForm])

    return <div className='flex justify-center items-center'>
        <Form
            layout='inline'
            onFinish={props.handleOk}
            form={otpForm}
            className='flex justify-center'
        >

            <Row className='w-100 mb-2 pl-3 pb-2 text-center justify-center'>
                Enter OTP
            </Row>


            <Form.Item
                rules={otpRules}
                name="otp1"
            >
                <Input
                    tabIndex="1"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    maxLength='1'
                    onKeyUp={e => inputFocus(e)}
                    style={otpStyle}
                />
            </Form.Item>
            <Form.Item
                rules={otpRules}
                name="otp2"
            >
                <Input
                    tabIndex="2"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    maxLength='1'
                    onKeyUp={e => inputFocus(e)}
                    style={otpStyle}
                />
            </Form.Item>
            <Form.Item
                rules={otpRules}
                name="otp3"
            >
                <Input
                    tabIndex="3"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    maxLength='1'
                    onKeyUp={e => inputFocus(e)}
                    style={otpStyle}
                />
            </Form.Item>
            <Form.Item
                rules={otpRules}
                name="otp4"
            >
                <Input
                    tabIndex="4"
                    type="text"
                    autoComplete="off"
                    className="otpInput"
                    maxLength='1'
                    onKeyUp={e => inputFocus(e)}
                    style={otpStyle}
                />
            </Form.Item>

            <Row className='w-100 justify-between my-3 pt-3' >
                <Button onClick={() => props.fetchOtp(props.phoneNumber)} type="text">Resend OTP</Button>
                {/* </Row>


        <Row
            justify='end'
        > */}
                <Form.Item >
                    <Button loading={props.confirmLoading} type='primary' htmlType="submit">
                        Verify OTP
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    </div>
}