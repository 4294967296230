import { Popover, Button, Menu, Divider } from "antd"
import { useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { ShoppingCartOutlined } from '@ant-design/icons'
import I from 'immutable'
import { Link } from 'react-router-dom';
import { HeartFilled } from '@ant-design/icons'

const CartDropDown = (props) => {

    const mapState = useCallback(({ app }) => ({
        cart_item_count: app.getIn(['cart_item_count'], 0),
        wish: app.getIn(['wish'], I.Map()),
        profile: app.getIn(['profile'], I.Map()),
    }), [])

    const { cart_item_count, wish, profile } = useSelector(mapState)

    const [isPopoverVisible, setIsPopoverVisible] = useState(false)
    const hidePopover = () => {
        setIsPopoverVisible(false);
    };
    const handleVisibleChange = (visible) => {
        setIsPopoverVisible(visible)
    }

    // const action = useCallback(() => {
    //     return (
    //         <div className="flex flex-col">
    //             <Link to="/cart" className='flex  __hover'
    //                 onClick={hidePopover}>
    //                 <span className="flex flex-1 align-center">
    //                     <ShoppingCartOutlined style={{ fontSize: 18, marginRight: 10 }} />
    //                     Cart</span>{cart.getIn(['cart_item_count'], 0) > 0 ? `(${cart.getIn(['cart_item_count'], 0)})` : ``}
    //             </Link>
    //             {
    //                 profile && profile.getIn(['isGuest'], true) ?
    //                     null : <>
    //                         <Divider className="mx-0 my-2" />
    //                         <Link to="/account/wishlist" className='flex __hover'
    //                             onClick={hidePopover}><span className="flex fex-row flex-1">
    //                                 <HeartFilled style={{ fontSize: 18, marginRight: 10 }} />
    //                                 Wish List</span>{wish?.getIn(['item_count'], 0) > 0 ? `(${wish?.getIn(['item_count'], 0)})` : ``}
    //                         </Link>
    //                     </>
    //             }

    //         </div >
    //     )
    // }, [cart, wish, profile])

    return (
        <div className="flex">
            <Link to="/cart" className='flex  __hover'
                onClick={hidePopover}>
                <Button shape="round" className="relative __btnStyle __mob" icon={<ShoppingCartOutlined style={{ fontSize: '20px' }} />} >

                    <span className="flex flex-1 align-center px-2 __IconOnly" >
                        {/* <ShoppingCartOutlined style={{ fontSize: 18, marginRight: 10 }} /> */}

                        Cart
                    </span>
                    {cart_item_count > 0 ?
                        <span className="absolute flex justify-center font-bold text-xs text-white hover:text-white border-white"
                            style={{ top: '-10px', right: '-5px', borderRadius: '50%', width: '12px', borderWidth: 1, height: '12px', backgroundColor: '#f29b00', alignItems: 'center', padding: '11px' }} >
                            {cart_item_count}
                        </span>
                        : null}
                </Button>
            </Link>
            {
                (profile?.getIn(['isGuest'], false) ?? true) ?
                    null :
                    <>
                        {/* <Divider className="mx-0 my-2" /> */}
                        <Link to="/account/wishlist" className='flex __hover __nav_btn'
                            onClick={hidePopover}>
                            <Button shape="round" className="relative __btnStyle __mob" icon={<HeartFilled style={{ fontSize: '20px' }} />}>
                                <span className="flex fex-row flex-1 align-center px-1 __IconOnly" >
                                    Wish List
                                </span>
                                {(wish?.size ?? 0) > 0 ? <span
                                    className="absolute flex justify-center font-bold text-xs text-white hover:text-white border-white"
                                    style={{
                                        top: '-10px', right: '-5px', borderRadius: '50%', width: '12px', borderWidth: 1,
                                        height: '12px', backgroundColor: '#f29b00', alignItems: 'center', padding: '11px'
                                    }} >
                                    {wish.size}
                                </span>
                                    : null}
                            </Button>
                        </Link>

                    </>
            }

        </div >
        //     <Popover
        //     content={action}

        //     // title={"Profile"}
        //     placement="bottomRight"
        //     // trigger="click"
        //     // trigger={['click', 'hover']}
        //     // trigger="hover"
        //     visible={isPopoverVisible}
        //     onVisibleChange={handleVisibleChange}
        //     overlayInnerStyle={{ padding: 0, minWidth: 150 }}
        // >
        //     {/* <Button shape="round" icon={<ShoppingCartOutlined />} style={{ margin: '0px 15px' }} /> */}
        //     <Button shape="round" icon={<ShoppingCartOutlined style={{ fontSize: '20px' }} />} style={{ margin: '0px 15px', display: 'flex', alignItems: "center", backgroundColor:'#1c9f47',color:'white' }} >Cart</Button>
        //     </Popover>
    )
}

export default CartDropDown