import axios from "axios";
import CONFIG from "../config/constants";

import { getCookie, getToken } from "./utils";
import log from "./logger";

const Axios = axios.create({
  baseURL: CONFIG.url.API_URL,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-CSRFToken",
  crossdomain: true,
  headers: {
    //   'X-User-Agent': `*`,
    //   'Content-Type':`multipart/form-data`
    Accept: `application/json`,
    "X-User-Agent": `abemart-react-web`,
    //   'Access-Control-Allow-Headers':'origin, content-type, accept'
  },
});

Axios.interceptors.response.use((response) => {
  if (response.status >= 400) {
    const data = {
      response: {
        data: response.data,
      },
      url: response.request.baseURL + response.request.url,
      request: {
        data: response.request.data,
      },
      status: response.status,
    };
    log("ERROR", data);
  }
  return response;
});

const transformErrorResponse = (error) => {

  let err = {};
  let data = error?.response?.data
  if (data?.error == null) { //error?.response?.status == 422 && 
    err = {
      message: data?.message,
    };
  }

  // if (typeof error == "string") {
  //   err = {
  //     message: error,
  //   };
  // } else if (error.detail && typeof error.detail == "string") {
  //   err = {
  //     message: error.detail,
  //   };
  // } else {
  //   err = error;
  // }

  err['status'] = error?.response?.status
  err['data'] = error?.response?.data

  if (error?.response?.status == 404) {
    err['message'] = 'We can\'t find what your are looking for....'
  }


  return err;
};

const transformResponse = (response) => {
  let res = response.data; //json();
  // if (res.status == false) {
  //     if (res.msg == "Invalid Token") {
  //         clearCookies()

  //         navigateToUrl('/login',true)
  //     }
  // }

  return res;
};

//ABEMART

const banner = () => {
  return new Promise((resolve, reject) => {
    Axios.get("banners", {
      params: {
        'zip': getCookie("pincode")
      }
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const categoryList = (martId) => {
  return new Promise((resolve, reject) => {
    Axios.get("categories", {
      params: {
        'mart': martId,
        'zip': getCookie("pincode"),
        'slot': getCookie("delivery_time_id"),
        'slot_count': getCookie("delivery_slot_count"), // integrate into api
      }

    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const deliveryslots = (martId) => {
  return new Promise((resolve, reject) => {
    Axios.get("v2/delivery", {
      params: {
        'mart': martId,
        'zip': getCookie("pincode"),
      },
      headers: {
        Authorization: `Bearer ` + getToken(),
      },

    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const offerList = () => {
  let params = "?zip=" + getCookie("pincode");
  return new Promise((resolve, reject) => {
    Axios.get("home" + params)
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const ordersList = (pageno, search) => {
  return new Promise((resolve, reject) => {
    Axios.get("v2/orders", {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
      params: {
        page: pageno,
        search: search
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const fetchOrder = (orderId) => {
  return new Promise((resolve, reject) => {
    Axios.get("v2/orders/" + orderId, {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const fetchReviews = (variantId) => {
  return new Promise((resolve, reject) => {
    Axios.get("v2/product_review/" + variantId, {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const submitReviews = (orderId, orderItemId, review, rating) => {
  return new Promise((resolve, reject) => {
    const payload = {};

    if (review) {
      payload.review = review;
    }

    if (rating) {
      payload.rating = rating;
    }
    Axios.post(`v2/orders_review/${orderId}/${orderItemId}`,{
      ...payload
    }, {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const deleteReview = (reviewId) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`v2/orders_review/${reviewId}`, {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const editReview = (reviewId, review, rating) => {
  return new Promise((resolve, reject) => {
    Axios.post(`v2/orders_review_edit/${reviewId}?rating=${rating}&review=${review}`, {}, {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const cancelOrder = (orderId) => {
  return new Promise((resolve, reject) => {
    Axios.delete("orders", {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
      params: {
        id: orderId,
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const fetchWishlist = () => {
  return new Promise((resolve, reject) => {
    Axios.get("wishlist", {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const searchList = (search) => {
  let params = "?search=" + search + "&zip=" + getCookie("pincode");
  return new Promise((resolve, reject) => {
    Axios.get("search" + params)
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const createGuestLogin = () => {
  return new Promise((resolve, reject) => {
    Axios.post("guest", {})
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const profileData = () => {
  return new Promise((resolve, reject) => {
    Axios.get("profile", {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const profileDataAll = () => {
  return new Promise((resolve, reject) => {
    Axios.get("v2/common-profile", {
      params: {
        'zip': getCookie("pincode")
      },
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};
const getOtp = (phone) => {
  console.log(phone);
  return new Promise((resolve, reject) => {
    Axios.post("get-otp", {
      phone: phone,
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};
const validateOtp = (phone, otp) => {
  let zip = getCookie("pincode");
  // let zip = "111111"
  return new Promise((resolve, reject) => {
    Axios.post(
      "validate-otp",
      {
        phone: phone,
        otp: otp,
        zip: zip,
      },
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const setProfile = (username, email, phone) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      "profile",
      {
        customer_name: username,
        customer_email: email,
        customer_phone: phone,
      },
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const fetchSearchResult = (mode, id, page) => {
  return new Promise((resolve, reject) => {
    Axios.get("search-result", {
      params: {
        mode: mode,
        id: id,
        page: page,
        // per_page: 1
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const fetchProductDetails = (id) => {
  return new Promise((resolve, reject) => {
    Axios.get("v2/variantproducts/" + id) //v2
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const fetchSearchProductResult = (mode, id, page) => {
  return new Promise((resolve, reject) => {
    Axios.get("search-productresult", {
      params: {
        mode: mode,
        id: id,
        page: page,
        'zip': getCookie("pincode")
        // per_page: 1
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const fetchOffer = (id) => {
  return new Promise((resolve, reject) => {
    Axios.get("home/" + id, {})
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(transformErrorResponse(result)));
  });
};

const fetchAddressList = () => {
  return new Promise((resolve, reject) => {
    Axios.get("address", {
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const fetchAddress = (addressId) => {
  return new Promise((resolve, reject) => {
    Axios.get("address/" + addressId, {
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};
const verifyEmail = () => {
  return new Promise((resolve, reject) => {
    Axios.get("resend-verification-mail", {
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const fetchStates = () => {
  return new Promise((resolve, reject) => {
    Axios.get("states", {
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const fetchZipcodes = (martId) => {
  return new Promise((resolve, reject) => {
    Axios.get("zipcodes", {
      params: {
        'type': martId
      },
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const deleteAddress = (addressId) => {
  return new Promise((resolve, reject) => {
    Axios.delete("address/" + addressId, {
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const createOrUpdateAddress = (
  addressId,
  full_name,
  phone,
  address_1,
  address_2,
  city,
  stateId,
  pincode,
  primary
) => {
  return new Promise((resolve, reject) => {
    if (addressId != null) {
      console.log("primary", primary);
      Axios.put(
        `address/${addressId}`,
        {
          id: addressId,
          address_name: full_name,
          address_phone: phone,
          address_building: address_1,
          address_road: address_2,
          address_city: city,
          address_state: stateId,
          address_zip: pincode,
          is_default: primary,
        },
        {
          headers: {
            Authorization: `Bearer ` + getToken(),
            "Content-Type": "application/json",
          },
        }
      ).then((response) => resolve(transformResponse(response)))
        .catch((result) => reject(transformErrorResponse(result)));
    } else {

      Axios.post(
        "address",
        {
          id: addressId,
          address_name: full_name,
          address_phone: phone,
          address_building: address_1,
          address_road: address_2,
          address_city: city,
          address_state: stateId,
          address_zip: pincode,
          is_default: primary,
        },
        {
          headers: {
            Authorization: `Bearer ` + getToken(),
            "Content-Type": "application/json",
          },
        }
      ).then((response) => resolve(transformResponse(response)))
        .catch((result) => reject(transformErrorResponse(result)));
    }


  });
};

const createOrUpdateAddress1 = (
  address
) => {

  return new Promise((resolve, reject) => {
    Axios.put(
      `address/${address.address_id}`,
      {
        id: address.address_id,
        address_name: address.address_name,
        address_phone: address.address_phone,
        address_building: address.address_building,
        address_road: address.address_road,
        address_city: address.address_city,
        address_state: address.state.state_id,
        address_zip: address.address_zip,
        is_default: address.is_default,
      },
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

// const fetchCart = () => {
//   return new Promise((resolve, reject) => {
//     Axios.get("cart", {
//       headers: {
//         Authorization: `Bearer ` + getToken(),
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => resolve(transformResponse(response)))
//       .catch((result) => reject(result));
//   });
// };
const fetchCartV2 = () => {
  return new Promise((resolve, reject) => {
    Axios.get("v2/cart", {
      params: {
        'zip': getCookie("pincode")
      },
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const add2Cart = (batch_id, quantity) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      "v2/add-to-cart?zip=" + getCookie("pincode"),
      {
        batch_id,
        quantity,
      },
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result.response));
  });
};

const removeCartItem = (cartItemId) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `v2/delete-cart-item/${cartItemId}?zip=${getCookie("pincode")}`,
      {},
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const fetchWishList = (page) => {
  return new Promise((resolve, reject) => {
    Axios.get("wishlist", {
      params: {
        page: page,
      },
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const fetchAvailableTimeSlot = (orderDeliveryMethodId) => {
  return new Promise((resolve, reject) => {
    Axios.get("delivery-slots/" + orderDeliveryMethodId, {
      headers: {
        Authorization: `Bearer ` + getToken(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const setTimeSlot = (deliveryTimeId, orderDeliveryMethodId,deliveryTimeCount) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      "set-timeslot",
      {
        order_delivery_method_id: orderDeliveryMethodId,
        delivery_time_id: deliveryTimeId,
        delivery_time_count: deliveryTimeCount,
      },
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const setCartCheckout = (voucher) => {
  return new Promise((resolve, reject) => {
    Axios.post("cart-checkout", voucher ? {
      'voucher': voucher
    } : {},
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result.response));
  });
};

// const fetchCartCheckout = () => {
//   return new Promise((resolve, reject) => {
//     Axios.get("cart-checkout", {
//       headers: {
//         Authorization: `Bearer ` + getToken(),
//         "Content-Type": "application/json",
//       },
//     })
//       .then((response) => resolve(transformResponse(response)))
//       .catch((result) => reject(result));
//   });
// };
const setPaymentStatus = (orderId, paymentStatus, paymentId, paymentType) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      "orders",
      {
        id: orderId,
        payment_status: paymentStatus,
        payment_details: paymentId,
        payment_type: paymentType
      },
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        }
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const updateWishList = (variant_id) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      "wishlist",
      {
        variant_id: variant_id,
      },
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const logout = () => {
  return new Promise((resolve, reject) => {
    Axios.post(
      "logout",
      {},
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};
const fetchPage = (key) => {
  return new Promise((resolve, reject) => {
    Axios.get("pages/" + key, {
      headers: {
        Authorization: `Bearer ` + getToken(),
      },
    })
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });
};

const checkVoucher = (code) => {
  return new Promise((resolve, reject) => {
    Axios.post("check_voucher", {
      'voucher': code
    },
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });

};

const fetchDayFreshDeliverySlots = () => {
  return new Promise((resolve, reject) => {
    Axios.get("v2/delivery_sort", 
      {
        headers: {
          Authorization: `Bearer ` + getToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => resolve(transformResponse(response)))
      .catch((result) => reject(result));
  });

};

export default {
  //ABEMART
  banner,
  categoryList,
  deliveryslots,
  offerList,
  ordersList,
  fetchOrder,
  fetchReviews,
  submitReviews,
  deleteReview,
  cancelOrder,
  fetchWishlist,

  searchList,
  createGuestLogin,
  profileData,
  profileDataAll,
  verifyEmail,
  getOtp,
  validateOtp,
  setProfile,
  fetchSearchResult,
  fetchProductDetails,
  fetchSearchProductResult,
  fetchOffer,
  fetchAddressList,
  fetchAddress,
  createOrUpdateAddress,
  createOrUpdateAddress1,
  fetchStates,
  fetchZipcodes,
  deleteAddress,
  // fetchCart,
  fetchCartV2,
  setCartCheckout,
  // fetchCartCheckout,
  setPaymentStatus,
  add2Cart,
  removeCartItem,
  fetchWishList,
  updateWishList,
  fetchAvailableTimeSlot,
  setTimeSlot,
  logout,
  fetchPage,
  checkVoucher,
  editReview,
  fetchDayFreshDeliverySlots
};
