import AppActionTypes from './AppActionTypes'
import { createAction } from '../../helpers/utils';

export default {
  showLoader: () => createAction(AppActionTypes.SHOW_LOADER),
  hideLoader: () => createAction(AppActionTypes.HIDE_LOADER),
  setCart: (data) => createAction(AppActionTypes.SET_CART, { data }),
  setWishList: (data) => createAction(AppActionTypes.SET_WISH_LIST, { data }),


  // ABEMART

  setProfile: (data, phone) => createAction(AppActionTypes.SET_PROFILE, { data, phone }),
  setProfileDataAll: (data) => createAction(AppActionTypes.SET_PROFILE_DATA_ALL, { data }),
  setPinCode: (pincode, cb) => createAction(AppActionTypes.SET_PIN_CODE, { pincode, cb }),
  setShowPinCode: (status) => createAction(AppActionTypes.SET_SHOW_PIN_CODE, { status }),
  setAddressList: (data) => createAction(AppActionTypes.SET_ADDRESS_LIST, { data }),



  requestFetchBanner: (cb) => createAction(AppActionTypes.REQUEST_FETCH_BANNER, { cb }),
  requestFetchCategoryList: (martId, cb) => createAction(AppActionTypes.REQUEST_FETCH_CATEGORY_LIST, { martId, cb }),
  requestFetchDeliverySlots: (martId, cb) => createAction(AppActionTypes.REQUEST_FETCH_DELIVERY_SLOTS, { martId, cb }),
  requestFetchOfferList: (cb) => createAction(AppActionTypes.REQUEST_FETCH_OFFER_LIST, { cb }),
  requestFetchSearchList: (search, cb) => createAction(AppActionTypes.REQUEST_FETCH_SEARCH_LIST, { search, cb }),
  requestCreateGuestLogin: (cb) => createAction(AppActionTypes.REQUEST_CREATE_GUEST_LOGIN, { cb }),

  requestFetchOTPData: (phone, cb) => createAction(AppActionTypes.REQUEST_FETCH_OTP_DATA, { phone, cb }),
  requestValidateOTPData: (phone, otp, cb) => createAction(AppActionTypes.REQUEST_VALIDATE_OTP_DATA, { phone, otp, cb }),
  requestCreateNameEmail: (username, email, phone, cb) => createAction(AppActionTypes.REQUEST_CREATE_NAME_EMAIL, { username, email, phone, cb }),
  requestFetchProfileData: (cb) => createAction(AppActionTypes.REQUEST_FETCH_PROFILE_DATA, { cb }),
  requestFetchProfileDataAll: (cb) => createAction(AppActionTypes.REQUEST_FETCH_PROFILE_DATA_ALL, { cb }),

  requestFetchSearchResult: (mode, id, page, cb) => createAction(AppActionTypes.REQUEST_FETCH_SEARCH_RESULT, { mode, id, page, cb }),
  requestFetchSearchProductResult: (mode, id, page, cb) => createAction(AppActionTypes.REQUEST_FETCH_SEARCH_PRODUCT_RESULT, { mode, id, page, cb }),
  requestFetchOffers: (id, cb) => createAction(AppActionTypes.REQUEST_FETCH_OFFER, { id, cb }),
  requestFetchProductDetails: (id, cb) => createAction(AppActionTypes.REQUEST_FETCH_PRODUCT_DETAILS, { id, cb }),
  requestUpdateProfile: (name, phone, email, cb) => createAction(AppActionTypes.REQUEST_UPDATE_PROFILE, { name, phone, email, cb }),
  requestVerifyEmail: (cb) => createAction(AppActionTypes.REQUEST_VERIFY_EMAIL, { cb }),
  requestFetchOrdersList: (pageno, search, cb) => createAction(AppActionTypes.REQUEST_FETCH_ORDERS_LIST, { pageno, search, cb }),
  requestFetchOrder: (orderId, cb) => createAction(AppActionTypes.REQUEST_FETCH_ORDER, { orderId, cb }),
  requestFetchReviews: (variantId, cb) => createAction(AppActionTypes.REQUEST_FETCH_REVIEWS, { variantId, cb }),
  requestSubmitReviews: (orderId, orderItemId, review, rating, cb) => createAction(AppActionTypes.REQUEST_SUBMIT_REVIEWS, { orderId, orderItemId, review, rating, cb }),
  requestdeleteReviews: (reviewId, cb) => createAction(AppActionTypes.REQUEST_DELETE_REVIEWS, { reviewId, cb }),
  requestEditReviews: (reviewId, review, rating, cb) => createAction(AppActionTypes.REQUEST_EDIT_REVIEWS, { reviewId, review, rating, cb}),
  requestCancelOrder: (orderId, cb) => createAction(AppActionTypes.REQUEST_CANCEL_ORDER, { orderId, cb }),
  requestFetchAddressList: (cb) => createAction(AppActionTypes.REQUEST_FETCH_ADDRESS_LIST, { cb }),
  requestFetchAddress: (addressId, cb) => createAction(AppActionTypes.REQUEST_FETCH_ADDRESS, { addressId, cb }),
  requestCreateOrUpdateAddress: (addressId, full_name, phone, address_1, address_2, city, stateId, pincode, primary, cb) => createAction(AppActionTypes.REQUEST_CREATE_OR_UPDATE_ADDRESS, { addressId, full_name, phone, address_1, address_2, city, stateId, pincode, primary, cb }),
  requestCreateOrUpdateAddress1: (address, cb) => createAction(AppActionTypes.REQUEST_CREATE_OR_UPDATE_ADDRESS1, { address, cb }),
  requestFetchStates: (cb) => createAction(AppActionTypes.REQUEST_FETCH_STATES, { cb }),
  requestFetchZipCodes: (martId, cb) => createAction(AppActionTypes.REQUEST_FETCH_ZIPCODES, { martId, cb }),
  requestDeleteAddress: (addressId, cb) => createAction(AppActionTypes.REQUEST_DELETE_ADDRESS, { addressId, cb }),
  requestFetchCart: (cb) => createAction(AppActionTypes.REQUEST_FETCH_CART, { cb }),
  requestFetchCartV2: (cb) => createAction(AppActionTypes.REQUEST_FETCH_CART_V2, { cb }),
  requestAdd2Cart: (batch_id, qty, cartItemId, cb) => createAction(AppActionTypes.REQUEST_ADD_2_CART, { batch_id, qty, cartItemId, cb }),
  requestFetchWishlist: (page, cb) => createAction(AppActionTypes.REQUEST_FETCH_WISH_LIST, { page, cb }),
  requestUpdateWishList: (variant_id, cb) => createAction(AppActionTypes.REQUEST_UPDATE_WISH_LIST, { variant_id, cb }),
  requestSetCartCheckout: (voucher, cb) => createAction(AppActionTypes.REQUEST_SET_CART_CHECKOUT, { voucher, cb }),
  requestFetchAvailableTimeSlot: (orderDeliveryMethodId, cb) => createAction(AppActionTypes.REQUEST_FETCH_AVAILABLE_TIME_SLOT, { orderDeliveryMethodId, cb }),
  requestSetTimeSlot: (deliveryTimeId, orderDeliveryMethodId,deliveryTimeCount, cb) => createAction(AppActionTypes.REQUEST_SET_TIME_SLOT, { deliveryTimeId, orderDeliveryMethodId, deliveryTimeCount, cb }),
  requestSetPaymentStatus: (orderId, paymentStatus, paymentId, paymentType, cb) => createAction(AppActionTypes.REQUEST_SET_PAYMENT_STATUS, { orderId, paymentStatus, paymentId, paymentType, cb }),
  requestLogout: (cb) => createAction(AppActionTypes.REQUEST_LOGOUT, { cb }),
  requestFetchPageDetails: (key, cb) => createAction(AppActionTypes.REQUEST_PAGE, { key, cb }),

  requestCheckVoucher: (code, cb) => createAction(AppActionTypes.REQUEST_CHECK_VOUCHER, { code, cb }),

  requestFetchDayFreshDeliverySlots: (cb) => createAction(AppActionTypes.REQUEST_FETCH_DAY_FRESH_DELIVERY_SLOTS, { cb }),

}
